import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Observable, Subscription } from 'rxjs';
import { DemandesTransService } from 'src/app/services/demandes-trans/demandes-trans.service';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';
import { StoresService } from 'src/app/services/stores/stores.service';
import { TeamsService } from 'src/app/services/teams-founix/teams.service';
import { UsersService } from 'src/app/services/users-founix/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  dataCmds: any = {}
  statusCmds: any = []

  cmdList: any = []
  subscription: Subscription;

  constructor(
    private cmdsService: DemandesTransService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusDemande().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.listCmdsByStatus()
    this.statisticFluxCmds()

    const source = interval(5000);
    this.subscription = source.subscribe(val => {
      this.listCmdsByStatus()
      this.statisticFluxCmds()
    });
  }

  async listCmdsByStatus() {
    //let userId = await this.clientService.getIdUser()
    this.cmdsService.allCmdsByStatus(null).then((res: any) => {
      console.log("res")
      console.log(res)
      if (res) {
        this.dataCmds = res;
      }
    });
  }

  async statisticFluxCmds() {
    await this.cmdsService.statisticFluxCmds().then((res: any) => {
      if (res) {
        console.log("cmdssssss res");
        console.log(res);
        this.cmdList = res;
      }

    });
  }

  goToNewTabDemande(idDemande, route) {
    this.cmdsService.goToNewTabDemande(idDemande, route)
  }

}
