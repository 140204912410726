import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users-founix/users.service';

@Component({
  selector: 'app-affectation-prepa-dialog',
  templateUrl: './affectation-prepa-dialog.component.html',
  styleUrls: ['./affectation-prepa-dialog.component.css']
})
export class AffectationPrepaDialogComponent implements OnInit {

  leadersList: any = [];
  
  constructor(public dialogRef: MatDialogRef<AffectationPrepaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trackingServices: UsersService) { }

    ngOnInit(): void {
      this.listLouagistes()
    }

  selectChangeHandler(event: any) {
    //update the ui
    // this.selectedUser = event.target.value;
    this.data.user = event.target.value
    // console.log("event.target.value")
    // console.log(event.target.value)
  }

  async listLouagistes() {
    await this.trackingServices.getPreparers().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
    });
  }

}
