<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-graph2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Dashboard
                <div class="page-title-subheading">Il s'agit d'un tableau de bord créé à l'aide
                    d'éléments et de composants intégrés.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['created'] }">
        <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En attente</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.created?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['validated'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Validées</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.validated?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['pick_uped'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Pick-UP</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.pick_uped?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['receive'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Au dépôt</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.received?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['transporting'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">En cours</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.inProgress?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']"
        [queryParams]="{ status: ['delivered'], paied: false }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livrée par le transporteur</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.deliveredUserTrack?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['paied'] }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livrée payée</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.paied?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-grouping']" [queryParams]="{ status: ['canceled'] }">
        <div class="card mb-3 widget-content bg-secondary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Annulée</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.canceled?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-grouping']"
        [queryParams]="{ returned_by: 'returned_exp' }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour expéditeur</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.returned_exp?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/returned-grouping']"
        [queryParams]="{ returned_by: 'returned_client' }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Retour client</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.returned_client?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">La liste des dépôts
                <div class="btn-actions-pane-right">
                    <button type="button" id="PopoverCustomT-1"
                        class="btn btn-danger btn-sm">{{storesList?.length}}</button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div *ngFor="let store of storesList" class="col-md-3" [routerLink]="['/details-store']"
                        [queryParams]="{ store: store._id }">
                        <div class="card mb-3 widget-content bg-info">
                            <div class="widget-content-wrapper text-white">
                                <div class="widget-content-left mr-3">
                                    <div class="icon-wrapper rounded-circle">
                                        <div class="icon-wrapper-bg bg-primary"></div>
                                        <i class="pe-7s-more text-white"></i>
                                    </div>
                                </div>
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{store.store_name}}</div>
                                </div>
                                <div class="widget-content-right">
                                    <div class="widget-numbers text-white"><span>{{store?.inStore}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12 col-lg-6">
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    Charges des livreurs
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let leader of leadersList">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <!-- <ngx-avatar class="rounded-circle"
                                                        name="{{leader.element.nom_prenom_tracking}}">
                                                    </ngx-avatar> -->
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{env.transportorUsersAssetsBaseURL + leader?.element?.profile_user}}"
                                                        alt="">
                                                </div>
                                                <div class="widget-content-left" style="width: 45%;">
                                                    <div class="widget-heading">
                                                        {{leader.element.nom_prenom_tracking}}</div>
                                                    <div class="widget-subheading">
                                                        {{leader.element.numTel_tracking}}</div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge">
                                                            {{leader.transportingCmd}}</div>
                                                        En cours
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-success">
                                                        <div class="style-badge">{{leader.liveredCmd}}
                                                        </div>Livrées
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-danger">
                                                        <div class="style-badge">{{leader.returningCmd}}
                                                        </div>Retoures
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-12 col-lg-6">
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    État actuel des clients WASSALI
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let element of clientsList, let index = index">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <ngx-avatar class="rounded-circle" name="{{element.client.prenom_client}}
                                                                {{element.client.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="widget-content-left" style="width: 55%;">
                                                    <div class="widget-heading">
                                                        {{element.client.prenom_client}}
                                                        {{element.client.nom_client}}
                                                        ({{element.client.company_name}})</div>
                                                    <div class="widget-subheading">
                                                        {{element.client.numTel_client}}</div>
                                                </div>
                                                <div class="widget-content-right" *ngIf="element.hasDemande">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge"><i class="fa fa-check"></i></div>
                                                        Demande
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-warning">
                                                        <div class="style-badge">{{element.nbrPickUp}}
                                                        </div>Pick-up
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>