import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logoFile from '../logo.js';

@Injectable({
  providedIn: 'root'
})
export class DemandesTransService {

  constructor(private http: HttpClient,
    private router: Router,
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe) { }

    accepteDemande(resDemande) {
      // console.log("ref : " + ref)
      return new Promise((slv) => {
        this.http.post(environment.apiAccepteDemande, resDemande).subscribe(
          (data: any) => {
            // console.log(JSON.stringify(data));
            // if (data) {
              slv(data);
            // } else slv(false);
          },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            slv(false);
          }
        );
      });
    }

  proposeDemande(idDemande, idDriver, position, proposition) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiResponseDemande, {
        "idDemande": idDemande,
        "idDriver": idDriver,
        "position": position,
        "proposition": proposition,
      }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          // if (data && data.response && data.data) {
            slv(data);
          // } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneTrans(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneTansportor, { "idTrans": idTrans }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  validateTransportor(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiValidateTansportor, { "iddriver": idTrans }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data) {
            slv(data);
          }// else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async blockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiBlockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async deblockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDeblockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getMarkerTransportors(name, numtel, vehicule) {
    return await new Promise((slv) => {
      this.http.post(environment.apiFilterMarkersTansportors, {
        name,
        numtel,
        vehicule,
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getTransportors() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTansportors, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getOneDemande(demandeId) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneDemande, { "idDemande": demandeId }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async demandesTransportors(page, status, name?, numero?, datein?, datefin?, service?, vehicule?, planified?, dateinPlan?, datefinPlan?) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllDemandes, {
        page,
        status: status,
        name: name,
        numtel: numero,
        startDate: datein,
        endDate: datefin,
        service,
        vehicule,
        planified,
        startDatePlan: dateinPlan,
        endDatePlan: datefinPlan,
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allTypeCamion() {
    return await new Promise((slv) => {
      this.http.post(environment.apiTypeCamion, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allServices() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetServices, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_demande: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllDemandesByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getLabeledStatusDemande() {
    return new Promise((slv) => {
      this.http.post(environment.apiStatusDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusPH, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async statisticFluxCmds() {
    return await new Promise((slv) => {
      this.http.post(environment.apiStatisticsFluxCmds, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTab(idDriver, route) {
    const queryParams = {
      transportor: idDriver
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  goToNewTabDemande(idDemande, route) {
    const queryParams = {
      demande: idDemande
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }
}
