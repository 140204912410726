<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-server icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Palettes
                <div class="page-title-subheading">La liste des palettes.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngFor="let palet of allPalets, let index = index">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{palet?.name}}
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="longueur" class="">Longueur</legend>
                                        <input name="longueur" id="longueur" type="text" class="form-control"
                                            value="{{palet.longueur}}" disabled>
                                            <span class="cm">m</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="largeur" class="">Largeur</legend>
                                        <input name="largeur" id="largeur" type="text" class="form-control"
                                            value="{{palet.largeur}}" disabled>
                                        <span class="cm">m</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="hauteur" class="">Hauteur</legend>
                                        <input name="hauteur" id="hauteur" type="text" class="form-control"
                                            value="{{palet.hauteur}}" disabled>
                                            <span class="cm">m</span>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="price" class="">Prix</legend>
                                        <input name="price" id="price" type="text" class="form-control"
                                            value="{{palet.price}}" disabled>
                                            <span class="cm">TND</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>