<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes de groupage en cours
                <div class="page-title-subheading">La liste des commandes de groupage en cours.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes de groupage en cours
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="allDemandes.length === 0">
                    <h5 class="text-center">Pas de commande</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="allDemandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Client</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date<br>de<br>création</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Total Commande</th>
                            <th class="text-center">
                                Services</th>
                            <th class="text-center">Statut</th>
                            <!-- <th class="text-center" data-width="100">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let cmd of allDemandes, let index = index">
                            <tr style="cursor: pointer;" (click)="goToNewTab(cmd._id, '/detail-grouping')">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                    <ngx-avatar class="rounded-circle" name="{{cmd.user_transportor.prenom_user}}
                                                {{cmd.user_transportor.nom_user}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">{{cmd.user_transportor.prenom_user}}
                                                    {{cmd.user_transportor.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd.user_transportor.email_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd.user_transportor.tel_user}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text">{{cmd.ref_grouping}}</td>
                                <td class="text-center">{{cmd.creation_date_grouping | date:'d MMM y'}}<br>
                                    <b>{{cmd.creation_date_grouping | date:'HH:mm'}}</b>
                                </td>
                                <td class="text-center">{{cmd?.user_location?.main_target}}<br>
                                    <strong>{{cmd?.user_location?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{cmd?.user_destination?.main_target}}<br>
                                    <strong>{{cmd?.user_destination?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{cmd.price |
                                    number:'0.3'}} DT</td>
                                <td class="text-center">
                                    <strong>{{cmd?.palette?.name}}</strong><br>
                                    <strong>{{cmd?.sub_family?.family?.name}}</strong><br>
                                    <strong>{{cmd?.sub_family?.name}}</strong><br>
                                    <strong>Quantité: {{cmd?.quantity}}</strong>
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-{{statusCmds[cmd?.etat_grouping]?.color}}">
                                        {{statusCmds[cmd?.etat_grouping]?.label}}</div>
                                </td>
                                <!-- <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-grouping')"
                                            tabindex="0" class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details
                                        </button>
                                        <button type="button" tabindex="0"
                                            (click)="goToNewTab(cmd._id, '/facture-grouping')" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td> -->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>