import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logoFile from '../logo.js';

@Injectable({
  providedIn: 'root'
})
export class GoupingService {

  constructor(private http: HttpClient,
    private router: Router,
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe) { }

  // CLIENTS PHONIX
  exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    const etatCmd = excelData.etatCmd;
    const startDate = excelData.startDate;
    const endDate = excelData.endDate;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');


    //Add Row and formatting
    worksheet.mergeCells('C1', 'G5');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 20,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    worksheet.mergeCells('H1:H5');
    let d = new Date();
    let date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('H1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    let myLogoImage = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.mergeCells('A2:B4');
    worksheet.addImage(myLogoImage, 'A2:B4');

    //Blank Row 
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.addRow([]);

    let subTitleRow = worksheet.addRow(['La liste des commandes ' + etatCmd + '(s) - de ' + startDate + " jusqu'au " + endDate]);
    subTitleRow.getCell(1).font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    };
    subTitleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    // subTitleRow.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    //Merge Cells
    worksheet.mergeCells(`A${subTitleRow.number}:H${subTitleRow.number}`);

    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    let index = 0
    let total = 0
    data.forEach(cmd => {
      // console.log(cmd)
      index++;
      total += cmd.price
      let row = worksheet.addRow([index.toString(),
      cmd?.ref_grouping,
      this._datePipe.transform(cmd?.creation_date_grouping, "d MMM y h:mm"),
      cmd?.user_transportor?.prenom_user + " " + cmd?.user_transportor?.nom_user + " - " + cmd.user_transportor.tel_user,
      cmd?.user_location?.main_target,
      cmd?.user_destination?.main_target,
      cmd?.palette?.name + "\n" + cmd?.sub_family?.family?.name + "\n" + cmd?.sub_family?.name + "\nQuantité: " + cmd?.quantity,
      this._decimalPipe.transform((cmd.price), "0.3") + " DT"
      ]);
      // let row = worksheet.addRow(d);

      let cities = row.getCell(7);
      cities.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }
      cities.alignment = { vertical: 'middle', horizontal: 'center' }

      let references = row.getCell(2);
      references.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }

      let sales = row.getCell(8);
      let color = 'FF99FF99';
      // if (+sales.value < 200000) {
      //   color = 'FF9999'
      // }
      sales.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        size: 12
      }
      sales.alignment = { vertical: 'middle', horizontal: 'right' }
      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );

    // worksheet.getColumn(3).width = 20;
    let endArray = worksheet.addRow([]);

    let totalCell = worksheet.getCell(`H${endArray.number + 1}`);
    totalCell.value = this._decimalPipe.transform(total, "0.3") + " DT"
    totalCell.font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    }
    totalCell.alignment = { vertical: 'middle', horizontal: 'right' }
    totalCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF99FF99' }
    }
    totalCell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    // //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Statistiques__Client__" + etatCmd + "__" + startDate + "_" + endDate + '.xlsx');
    })

  }
  statisticsCmds(client, startDate, endDate) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsCmds, {
        client,
        startDate,
        endDate,
        // ville
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  addSociety(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddSociety, formData).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          if (res) {
            if (res.response) {
              slv(res.response);
            } else {
              slv(res);
            }
          }
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async allSocieties() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllSocieties, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getPendingDemandes() {
    return await new Promise((slv) => {
      this.http.post(environment.apiPendingDemandes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatus, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getLabeledStatusGroupage() {
    return new Promise((slv) => {
      this.http.post(environment.apiStatusGroupage, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status, isPaied, returnedBy) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_grouping: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus,
        paied: isPaied,
        returnedBy: returnedBy
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  updateCmdStatus(id, status) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiUpdateCmdStatus, {
          idCmd: id,
          newstatus: status,
        })
        .subscribe(
          (data: any) => {
            // console.log("data update status : ");
            // console.log(JSON.stringify(data));
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  filterCmds(page, ref, status, ville, numero, datein, datefin) {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiFilterCmds, {
          page,
          ref: ref,
          status: status,
          ville: ville,
          numtel: numero,
          startDate: datein,
          endDate: datefin
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  addService(name) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiAddServices, {
          "nameFamily": name
        })
        .subscribe(
          (data: any) => {
            if (data) {
              slv(data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
    });
  }
  editService(id, name) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiEditService, {
          "idFamily": id,
          "nameFamily": name
        })
        .subscribe(
          (data: any) => {
            if (data) {
              slv(data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
    });
  }
  addSubService(name, idFamily) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiAddSubServices, {
          "nameSubFamily": name,
          "idFamily": idFamily
        })
        .subscribe(
          (data: any) => {
            if (data) {
              slv(data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
    });
  }
  editSubService(id, name) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiEditSubServices, {
          "nameSubFamily": name,
          "idSubFamily": id
        })
        .subscribe(
          (data: any) => {
            if (data) {
              slv(data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
    });
  }
  allServices() {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiAllServices, {

        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  allSubServices() {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiAllSubServices, {

        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  visibleServices(idFamily) {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiVisibleServices, {
          "idFamily": idFamily
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  visibleSubServices(idSubFamily) {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiVisibleSubServices, {
          "idSubFamily": idSubFamily
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  allPalets() {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiAllPalets, {

        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  searchCmd(idClient, keyword) {
    return new Promise((slv) => {
      this.http.post(environment.apiSearchCmd, {
        client: idClient,
        keyword: keyword
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneCMD(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmd + "/" + ref, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneFamily(idFamily) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneService, { idFamily: idFamily }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneSubFamily(idSubFamily) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneSubService, { idSubFamily: idSubFamily }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneGrouping(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneGrouping + "/" + ref, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  affectTransport(cmds, user) {
    return new Promise((slv) => {
      this.http.post(environment.apiAffectTransport, { cmds: cmds, user: user }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    })
  }
  getCons() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCons, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  generateCons() {
    return new Promise((slv) => {
      this.http.post(environment.apiGenerateCons, {}).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  goToNewTab(idCmd, route) {
    const queryParams = {
      cmd: idCmd
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }
}
