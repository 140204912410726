<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-culture icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Clients
                <div class="page-title-subheading">La liste des clients enregistrés.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-warning" [routerLink]="['/add-client-phoenix']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Ajouter un client
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Clients
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="allSocties.length === 0">
                    <h2 class="text-center">Pas de client</h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="allSocties.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Client</th>
                            <th class="text-center">Commandes<br>en<br>attente</th>
                            <th class="text-center">Commandes<br>en<br>cours</th>
                            <th class="text-center">Commandes<br>livrée</th>
                            <th class="text-center">Commandes<br>payée</th>
                            <th class="text-center">Commandes<br>annulée</th>
                            <th class="text-center">Total<br>des<br>commandes</th>
                            <th class="text-center">Etat</th>
                            <!-- <th class="text-center">Pick UP</th> -->
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let society of allSocties, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + society?.user_transportor?.profile_user}}"
                                                    alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{society.user_transportor.prenom_user}}
                                                {{society.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{society?.user_transportor?.prenom_user}}
                                                {{society?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{society?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                <b>{{society?.user_transportor?.tel_user}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-warning">
                                    {{filterCommandes(society?.grouping, ['created'])}}</div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-info">
                                    {{filterCommandes(society?.grouping, ["validated", "pick_uped", "transporting",
                                    "receive"])}}</div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-success">
                                    {{filterCommandes(society?.grouping, ['delivered'])}}</div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-success">
                                    {{filterCommandes(society?.grouping, ['paied'])}}</div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-danger">
                                    {{filterCommandes(society?.grouping, ['canceled'])}}</div>
                            </td>
                            <td class="text-center"><b>{{society?.grouping?.length}}</b></td>
                            <td class="text-center">
                                <div class="badge badge-success">
                                    Validé
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" [routerLink]="['/detail-client']"
                                            [queryParams]="{ client: society?.user_transportor?._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details société
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>