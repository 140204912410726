import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users-founix/users.service';

@Component({
  selector: 'app-add-vehicule',
  templateUrl: './add-vehicule.component.html',
  styleUrls: ['./add-vehicule.component.css']
})
export class AddVehiculeComponent implements OnInit {

  constructor(private usersService: UsersService) { }

  ngOnInit(): void {
  }
  
  async addVehicule(vehiculeName) {
    if (vehiculeName.value!=null && vehiculeName.value!="") {
      await this.usersService.addVehicule(vehiculeName.value)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            vehiculeName.value = ""
            // location.href = '/stores';
            //this.sacsList = res;
          }
        });
    } else {
      alert("Il faut ajouter les champs obligatoire !!!")
    }
  }


}
