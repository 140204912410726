import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { QRCodeModule } from 'angularx-qrcode';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { TopComponent } from './components/shared/top/top.component';
import { AvatarModule } from 'ngx-avatar';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { AllCommandesComponent } from './pages/dashboard/all-commandes/all-commandes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesComponent } from './pages/services/services/services.component';
import { PaletsComponent } from './pages/services/palets/palets.component';
import { SubServicesComponent } from './pages/services/sub-services/sub-services.component';
import { AddServiceComponent } from './pages/services/add-service/add-service.component';
import { AddSubServiceComponent } from './pages/services/add-sub-service/add-sub-service.component';
import { TransportorsComponent } from './pages/users-founix/transportors/transportors.component';
import { AddTransportorComponent } from './pages/users-founix/add-transportor/add-transportor.component';
import { DetailGroupingComponent } from './pages/grouping-founix/detail-grouping/detail-grouping.component';
import { FactureGroupingComponent } from './pages/grouping-founix/facture-grouping/facture-grouping.component';
import { ReturnedCommandesComponent } from './pages/dashboard/returned-commandes/returned-commandes.component';
import { GroupingTrackingUserComponent } from './pages/users-founix/grouping-tracking-user/grouping-tracking-user.component';
import { AffectationGroupingComponent } from './components/shared/dialogs/affectation-grouping/affectation-grouping.component';
import { ConsComponent } from './pages/services/cons/cons.component';
import { AddStoreComponent } from './pages/stores/add-store/add-store.component';
import { StoresComponent } from './pages/stores/stores/stores.component';
import { DetailsStoreComponent } from './pages/stores/details-store/details-store.component';
import { AffectationPrepaDialogComponent } from './components/shared/dialogs/affectation-prepa-dialog/affectation-prepa-dialog.component';
import { ReceptionsComponent } from './pages/users-founix/receptions/receptions.component';
import { PendingGroupingComponent } from './pages/grouping-founix/pending-grouping/pending-grouping.component';
import { EditServiceComponent } from './pages/services/edit-service/edit-service.component';
import { EditSubServiceComponent } from './pages/services/edit-sub-service/edit-sub-service.component';
import { StatisticsTransportorComponent } from './pages/users-founix/statistics-transportor/statistics-transportor.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { AddClientComponent } from './pages/clients/add-client/add-client.component';
import { StatisticsCommandesComponent } from './pages/clients/statistics-commandes/statistics-commandes.component';
import { AuthGuard } from './guard/auth.guard';
import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { TeamsPhoenixComponent } from './pages/teams/teams-phoenix/teams-phoenix.component';
import { AddUserPhoenixComponent } from './pages/teams/add-user-phoenix/add-user-phoenix.component';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging/messaging.service';
import { AddVehiculeComponent } from './pages/users-founix/add-vehicule/add-vehicule.component';
import { AffectationVehiculeComponent } from './components/shared/dialogs/affectation-vehicule/affectation-vehicule.component';
import { GrouppageComponent } from './pages/dashboard/grouppage/grouppage.component';
import { StatusDemandesComponent } from './pages/dashboard/status-demandes/status-demandes.component';
import { RechercheDemandesComponent } from './pages/dashboard/recherche-demandes/recherche-demandes.component';
import { AllDemandesComponent } from './pages/dashboard/all-demandes/all-demandes.component';
import { DetailsDemandeComponent } from './pages/demandes/details-demande/details-demande.component';
import { ListTransportorsPhComponent } from './pages/demandes/list-transportors-ph/list-transportors-ph.component';
import { PositionsTransportorsPhComponent } from './pages/demandes/positions-transportors-ph/positions-transportors-ph.component';
import { DetailsTransportorPhComponent } from './pages/demandes/details-transportor-ph/details-transportor-ph.component';
import { ImageViewerComponent } from './components/shared/dialogs/image-viewer/image-viewer.component';
import { AffecteTransDialogComponent } from './components/shared/dialogs/affecte-trans-dialog/affecte-trans-dialog.component';
import { AccepteDemandeDialogComponent } from './components/shared/dialogs/accepte-demande-dialog/accepte-demande-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
    vertical: {
      position: 'top',
    },
  },
  theme: 'material',
};

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    FooterComponent,
    SidebarComponent,
    MenuComponent,
    TopComponent,
    LoginComponent,
    DashboardComponent,
    StatusCommandesComponent,
    RechercheComponent,
    AllCommandesComponent,
    ServicesComponent,
    PaletsComponent,
    SubServicesComponent,
    AddServiceComponent,
    AddSubServiceComponent,
    TransportorsComponent,
    AddTransportorComponent,
    DetailGroupingComponent,
    FactureGroupingComponent,
    ReturnedCommandesComponent,
    GroupingTrackingUserComponent,
    AffectationGroupingComponent,
    ConsComponent,
    AddStoreComponent,
    StoresComponent,
    DetailsStoreComponent,
    AffectationPrepaDialogComponent,
    ReceptionsComponent,
    PendingGroupingComponent,
    EditServiceComponent,
    EditSubServiceComponent,
    StatisticsTransportorComponent,
    ClientsComponent,
    AddClientComponent,
    StatisticsCommandesComponent,
    TeamsPhoenixComponent,
    AddUserPhoenixComponent,
    AddVehiculeComponent,
    AffectationVehiculeComponent,
    GrouppageComponent,
    StatusDemandesComponent,
    RechercheDemandesComponent,
    AllDemandesComponent,
    DetailsDemandeComponent,
    ListTransportorsPhComponent,
    PositionsTransportorsPhComponent,
    DetailsTransportorPhComponent,
    ImageViewerComponent,
    AffecteTransDialogComponent,
    AccepteDemandeDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgReduxModule,
    HttpClientModule,
    MatDialogModule,
    PerfectScrollbarModule,
    NgbModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    FormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    QRCodeModule,
    AvatarModule
  ],
  providers: [AuthGuard,
    DecimalPipe, DatePipe, AsyncPipe, MessagingService,
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
