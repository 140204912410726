<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-diamond icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Sous Familles
                <div class="page-title-subheading">Modifier la sous famille.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="socities" class="">Sous famille</legend>
                                    <input type="text" name="nameSubFamily" [(ngModel)]="subFamily.name">
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="subFamily.name==null || subFamily.name==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="socities" class="">Famille</legend>
                                    <!-- <select name="families" id="families" class="form-control"
                                        [(ngModel)]="idFamily">
                                        <option [ngValue]="null">
                                            Selectionnez la famille
                                        </option>
                                        <option *ngFor="let family of allFamilies" [ngValue]="family._id"
                                            ngDefaultControl>{{family?.name}}
                                        </option>
                                    </select> -->
                                    <input type="text" name="family" disabled [(ngModel)]="subFamily.family.name">
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" (click)="editSubFamily()"
                                class="btn btn-primary btn-lg btn-block">
                                Modifier la sous famille
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>