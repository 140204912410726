import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {

  constructor(private cmdsService: GoupingService) { }

  ngOnInit(): void {
  }

  async addFamily(name) {
    if (name.value != "") {
      await this.cmdsService.addService(name.value).then((res: any) => {
        if (res && res.response == true) {
          location.href = '/services'
        }
      });
    } else {
      alert('Les données de la famille est invalide');
    }
  }

}
