import { Component } from '@angular/core';
import { MessagingService } from './services/messaging/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'founix-suivie';
  message;

  constructor(private messagingService: MessagingService) { }

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage
    // console.log("this.message")
    // console.log(this.message)
   }
}
