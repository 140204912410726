import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {select} from '@angular-redux/store';
import { ThemeOptions } from 'src/app/theme-options';
import { TeamsService } from 'src/app/services/teams-founix/teams.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  currentUser: any;

  public extraParameter: any;
  @select('config') public config$: Observable<any>;

  constructor(public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private authenticationService: TeamsService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  get isAdmin() {
    // console.log("isAdmin")
    // console.log(this.currentUser)
    // arr1.some(r=> arr2.includes(r))
    return this.currentUser && this.currentUser.profiles.some(r => ["admin"].includes(r));
  }
  
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  closeToggleSidebarMobile() {
    this.globals.toggleSidebarMobile = false;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

}
