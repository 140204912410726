<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Utilisateur Phoenix
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">Ajouter un nouvel utilisateur Phoenix.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
        </div> -->
    </div>
</div>

<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-4 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <legend>Prénom</legend>
                                    <input type="text" class="form-control" name="first_name"
                                        ngModel #first_name>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="first_name.value==''">Entrez le prénom de
                                        l'utilisateur
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <div class="Neon Neon-theme-dragdropbox">
                                        <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                    left: 0px; margin-right: auto; margin-left: auto;" name="file" id="file" #file accept="image/*"
                                            (change)="handleFileInput($event.target.files)" type="file">
                                        <div class="Neon-input-dragDrop">
                                            <div class="Neon-input-inner">
                                                <div class="Neon-input-icon" *ngIf="imageUrl==''">
                                                    <i class="fa fa-file-image-o"></i>
                                                </div>
                                                <div class="Neon-input-icon" *ngIf="imageUrl!=''">
                                                    <img [src]="imageUrl" style="width:100px">
                                                </div>
                                                <div class="Neon-input-text">
                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                </div><a class="Neon-input-choose-btn blue">Parcourir</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="imageUrl==''">Ajouter
                                        la photo de profile
                                    </mat-error> -->
                                </div>
                            </div>
                            <div class="col-4 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <legend>Nom</legend>
                                    <input type="text" class="form-control" name="last_name" ngModel
                                        #last_name>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="last_name.value==''">Entrez le prénom de
                                        l'utilisateur
                                    </mat-error>
                                </div>                                
                                <div class="form-group">
                                    <legend>Email</legend>
                                    <input type="text" class="form-control" name="email_user"
                                        ngModel #email_user>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="email_user.value==''">Entrez l'email de l'utilisateur
                                    </mat-error>
                                </div>
                                <div class="form-group">
                                    <legend>Numéro de téléphone</legend>
                                    <input type="number" class="form-control" name="tel_user"
                                        ngModel #tel_user>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="tel_user.value=='' 
                                                || (tel_user.value!='' && tel_user.value.length!=8)">Entrez num tel de l'utilisateur
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-4 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <legend for="profiles">Roles</legend>
                                    <select class="form-control" name="profiles"
                                        [(ngModel)]="profiles" multiple style="height:180px">
                                        <!-- <option value="-"></option> -->
                                        <option *ngFor="let profile of allProfiles"
                                            [ngValue]="profile">
                                            {{profile}}</option>
                                    </select>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="profiles.length==0">
                                        Selectionnez au moins un profil
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <legend>Mot de passe</legend>
                                    <input type="password" class="form-control" name="password_user"
                                        ngModel #password_user>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="password_user.value==''">Entrez le mot de passe
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <legend>Confirmez le mot de passe</legend>
                                    <input type="password" class="form-control" name="confirme_password_user"
                                        ngModel #confirme_password_user>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="confirme_password_user.value=='' || 
                                        confirme_password_user.value!=password_user.value">Confirmez le mot de passe
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit"
                                (click)="addUserPH(first_name, email_user, last_name, tel_user, 
                                password_user, confirme_password_user)"
                                class="btn btn-primary btn-lg btn-block">
                                Ajouter un utilisateur
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
