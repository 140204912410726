<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-diamond icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Familles
                <div class="page-title-subheading">Modifier la famille.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-12">
                                <div class="form-group">
                                    <legend for="socities" class="">Famille</legend>
                                    <input type="text" name="family" [(ngModel)]="family.name">
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="family.name==null || family.name==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" (click)="editFamily()"
                                class="btn btn-primary btn-lg btn-block">
                                Modifier une famille
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>