import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-statistics-commandes',
  templateUrl: './statistics-commandes.component.html',
  styleUrls: ['./statistics-commandes.component.css']
})
export class StatisticsCommandesComponent implements OnInit {

  userId: String = null
  statsGroupings: any = {}
  cmdList: any = [];
  statusCmds: any = []
  clientsList: any = []
  etatGrouping: string = ""

  constructor(private cmdsService: GoupingService,) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listClients()
  }

  async inventaire(datein, datefin) {
    if (datein.value != "" && datefin.value != "") {
      await this.cmdsService.statisticsCmds(this.userId, datein.value, datefin.value).then((res: any) => {
        // console.log("res statisticsCmds : ")
        // console.log(res)
        if (res) {
          this.statsGroupings = res;
        }
      });
    } else {
      alert('Veuillez remplir les champs obligatoires !');
      this.statsGroupings = {}
      this.cmdList = []
    }
  }

  selectClient(event: any) {
    // console.log(event.target.value)
    if (event.target.value == "Selectionnez le client") {
      this.userId = null;
    } else {
      this.userId = event.target.value;
    }
  }

  async listClients() {
    await this.cmdsService.allSocieties().then((res: any) => {
      console.log(res)
      if (res) {
        this.clientsList = res;
        // this.clientsList.splice(0, 0, "Selectionnez le client");
      }
    });
  }

  displayCmdsByStatus(status) {
    if (status == "created") {
      this.etatGrouping = "En attente"
      this.cmdList = this.statsGroupings.allGroupingPending
    } else if (status == "validated") {
      this.etatGrouping = "validée"
      this.cmdList = this.statsGroupings.allGroupingValidated
    } else if (status == "pick_uped") {
      this.etatGrouping = "Commande récupérée"
      this.cmdList = this.statsGroupings.allGroupingDepot
    } else if (status == "transporting") {
      this.etatGrouping = "transportée"
      this.cmdList = this.statsGroupings.allGroupingTransporting
    } else if (status == "delivered") {
      this.etatGrouping = "livrée"
      this.cmdList = this.statsGroupings.allGroupingLivred
    } else if (status == "paied") {
      this.etatGrouping = "payé"
      this.cmdList = this.statsGroupings.allGroupingPaied
    } else if (status == "returned") {
      this.etatGrouping = "retournée"
      this.cmdList = this.statsGroupings.allGroupingReturned
    } else if (status == "canceled") {
      this.etatGrouping = "annulée"
      this.cmdList = this.statsGroupings.allGroupingCanceled
    } else {
      this.etatGrouping = ""
      this.cmdList = []
    }
  }

  exportToExcel(datein, datefin) {
    let reportData = {
      title: 'Statistique des commandes',
      data: this.cmdList,
      headers: ["#", "Référence", "Date de Création",
        "Nom Client & Téléphone", "Localisation", "Destination",
        "Services", 
        "Total Commande"], // Object.keys(this.cmdList[0])
      etatCmd: this.etatGrouping,
      startDate: datein.value,
      endDate: datefin.value
    }

    this.cmdsService.exportExcel(reportData);
  }

}
