<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-photo-gallery icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes
                <div class="page-title-subheading">La liste des commandes de tous les client.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterCmds(ref, numero, datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">Référence</legend>
                                        <input name="ref" id="ref" type="text" class="form-control" #ref ngModel>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Statut</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric" (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text" class="form-control" #numero
                                            ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            #datefin ngModel>
                                    </div>
                                </div>
                                <button type="submit" (click)="filterCmds(ref, numero, datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                                <!-- <div class="col-md-1">
                                                        <div class="position-relative form-group">
                                                            <legend for="firstName" class="">dd</legend>
                                                            <button class="mb-2 mr-2 btn btn-primary">
                                                                <i class="nav-link-icon pe-7s-search"></i>
                                                            </button>
                                                        </div>
                                                    </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <!-- <div class="btn-actions-pane-right">
                                    <div role="group" class="btn-group-sm btn-group">
                                        <button class="active btn btn-focus">Last Week</button>
                                        <button class="btn btn-focus">All Month</button>
                                    </div>
                                </div> -->
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                            <h2 class="text-center">Pas de commande</h2>
                        </div>
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="cmdList?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Client</th>
                                    <th class="text-left">Référence</th>
                                    <th class="text-center">Date<br>de<br>création</th>
                                    <th class="text-center">Localisation</th>
                                    <th class="text-center">Destination</th>
                                    <th class="text-center">Total Commande</th>
                                    <th class="text-center">
                                        Services</th>
                                    <th class="text-center">Statut</th>
                                    <th class="text-center" data-width="100">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of cmdList, let index = index">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                        <ngx-avatar class="rounded-circle" name="{{cmd.user_transportor.prenom_user}}
                                                {{cmd.user_transportor.nom_user}}">
                                                        </ngx-avatar>
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">{{cmd.user_transportor.prenom_user}}
                                                        {{cmd.user_transportor.nom_user}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_transportor.email_user}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_transportor.tel_user}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text">{{cmd.ref_grouping}}</td>
                                    <td class="text-center">{{cmd.creation_date_grouping | date:'d MMM y'}}<br>
                                        <b>{{cmd.creation_date_grouping | date:'HH:mm'}}</b>
                                    </td>
                                    <td class="text-center">{{cmd?.user_location?.main_target}}<br>
                                        <strong>{{cmd?.user_location?.sencondary_target}}</strong>
                                    </td>
                                    <td class="text-center">{{cmd?.user_destination?.main_target}}<br>
                                        <strong>{{cmd?.user_destination?.sencondary_target}}</strong>
                                    </td>
                                    <td class="text-center">{{cmd.price |
                                        number:'0.3'}} DT</td>
                                    <td class="text-center">
                                        <strong>{{cmd?.palette?.name}}</strong><br>
                                        <strong>{{cmd?.sub_family?.family?.name}}</strong><br>
                                        <strong>{{cmd?.sub_family?.name}}</strong><br>
                                        <strong>Quantité: {{cmd?.quantity}}</strong>
                                    </td>
                                    <td class="text-center">
                                        <div class="badge badge-{{statusCmds[cmd.etat_grouping].color}}">
                                            {{statusCmds[cmd.etat_grouping].label}}</div>
                                    </td>
                                    <td class="text-center">
                                        <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                            <button class="btn-wide btn btn-info">Actions</button>
                                            <button type="button" ngbDropdownToggle
                                                class="dropdown-toggle-split btn btn-info"><span
                                                    class="sr-only">Actions</span></button>
                                            <div ngbDropdownMenu>
                                                <button type="button" (click)="goToNewTab(cmd._id, '/detail-grouping')"
                                                    tabindex="0" class="dropdown-item">
                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                        style="margin-right: 10px;"></i>Details
                                                </button>
                                                <button type="button" tabindex="0"
                                                    (click)="goToNewTab(cmd._id, '/facture-grouping')"
                                                    class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                        style="margin-right: 10px;"></i>Facture</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1"
                                        (click)="previousPage(ref, numero, datein, datefin)">
                                        <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                        Précédent</a>
                                </li>

                                <div *ngFor="let p of pagesDisplaied">
                                    <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                        <a *ngIf="currentPage!==p" class="page-link"
                                            (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                        <span *ngIf="currentPage===p" class="page-link">
                                            {{p+1}}
                                            <span class="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </div>
                                <!-- <li class="page-item active">
                                                    <span class="page-link">
                                                        2
                                                        <span class="sr-only">(current)</span>
                                                    </span>
                                                </li>
                                                <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                                <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1"
                                        (click)="nextPage(ref, numero, datein, datefin)">Suivant <i
                                            class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                </li>
                            </ul>
                        </nav>

                        <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                        <button class="btn-wide btn btn-success">Save</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>