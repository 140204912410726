<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-home icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Dépôts
                <div class="page-title-subheading">La liste des dépôts.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngFor="let store of storesList">
    <div class="col-md-3">
        <div class="card mb-3 bg-premium-dark widget-chart text-white card-border">
            <div class="photo-wrapper rounded-circle">
                <div class="icon-wrapper-bg bg-white opacity-1"></div><img class="rounded-circle"
                    width="300" src="assets/images/store.png" alt="product">
            </div>
            <div class="widget-heading">Dépôt: {{store?.store_name }}</div>
            <div class="widget-heading fsize-1 text-info">Commandes en charge: <span
                class="badge badge-pill badge-light">{{store.cmds.length}}</span> </div>
                <div class="widget-heading">{{store?.types_colis}}</div>
            <div class="widget-description text-success" >
                <button class="mb-2 mr-2 btn btn-danger" [routerLink]="['/details-store']"
                [queryParams]="{ store: store._id }">Détail du dépôt
                    </button>
            </div>
        </div>
    </div>
    <div class="col-md-9">
        <div class="main-card mb-3 card">
            <div class="card-header">L'équipe - {{store?.prepas?.length}} membre actif :
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="btn btn-danger btn-sm" (click)="affectStorePrepa(store._id)">Ajouter un réceptionniste</button>
                        <!-- <button class="btn btn-focus">All Month</button> -->
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Nom</th>
                            <th class="text-center">Numéro<br>de<br>téléphone</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of store.prepas, let index = index">
                            <td class="text-center text-muted">{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle"
                                                    name="{{user.nom_prenom_tracking}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{user.nom_prenom_tracking}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{user.username_tracking}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{user?.numTel_tracking}}</td>
                            <td class="text-center">
                                <button type="button" id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer">
                <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button> -->
            </div>
        </div>
    </div>
</div>