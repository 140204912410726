<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Affectation
                <div class="page-title-subheading">La liste des commandes affectés.
                </div>
            </div>
        </div>

        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-warning" (click)="generateCons()">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Générer QR Code
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <!-- <div class="card-header">Active Users
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div>
            </div> -->
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>QR Code</th>
                            <th class="text-center">Date<br>d'ajout</th>
                            <!-- <th class="text-center">Nombre<br>des colis</th> -->
                            <th class="text-center">Statut</th>
                            <!-- <th class="text-center">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of consList, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="element._id"
                                    [errorCorrectionLevel]="'M'">
                                </qrcode>
                            </td>
                            <!-- <td class="text-center">{{element.client?.pickUpCollector?.name_collector}}
                                <br><strong>{{element.client?.pickUpCollector?.numTel_collector}}</strong>
                            </td> -->
                            <td class="text-center">
                                <b>{{element?.date_ajout_cons | date:'dd/MM/yyyy'}}</b>
                            </td>
                            <!-- <td class="text-center"><strong>{{element?.commandes.length}}</strong>
                            </td> -->
                            <td class="text-center">
                                <div
                                    [class]="(element?.etat_cons == 'prepared') ? 'badge badge-warning' : 'badge badge-danger'">
                                    {{displayStatus(element?.etat_cons)}}</div>
                            </td>
                            <!-- <td class="text-center">
                                <button type="button" id="PopoverCustomT-1" class="btn btn-primary btn-sm"
                                    (click)="showCommandes(element?.commandes)">Détail colis</button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>