import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/services/users-founix/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-receptions',
  templateUrl: './receptions.component.html',
  styleUrls: ['./receptions.component.css']
})
export class ReceptionsComponent implements OnInit {

  preparersList: Observable<Array<any>>;

  env: any;

  constructor(private trackingServices: UsersService) { }

  ngOnInit(): void {
    this.env = environment
    this.listPreparers()
  }

  async listPreparers() {
    await this.trackingServices.getPreparers().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.preparersList = res;
      }
     
    });
  }

}
