import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamsService } from '../services/teams-founix/teams.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private teamService: TeamsService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.teamService.currentUserValue;
    if (currentUser) {
      // console.log("currentUser")
      // console.log(currentUser)
      // check if route is restricted by role
      // console.log("condition")
      // console.log(route.data.roles!=null && !route.data.roles.includes(currentUser.profiles))

      
    // arr1.some(r=> arr2.includes(r))
      if (route.data.roles && !route.data.roles.some(r=> currentUser.profiles.includes(r))) {
        
      // console.log("route.data.roles")
      // console.log(route.data.roles)
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;

    // if (this.teamService.isConnected()) {
    //   return true
    // } else {
    //   this.router.navigate(['/login'])
    //   return false
    // }
  }

}
