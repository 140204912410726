import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';
import { UsersService } from 'src/app/services/users-founix/users.service';

@Component({
  selector: 'app-statistics-transportor',
  templateUrl: './statistics-transportor.component.html',
  styleUrls: ['./statistics-transportor.component.css']
})
export class StatisticsTransportorComponent implements OnInit {

  userId: string = null
  statsGroupings: any = {}
  cmdList: any = [];
  statusCmds: any = []
  leadersList: any = []
  etatGrouping: string = ""

  constructor(private cmdsService: GoupingService,
    private trackingServices: UsersService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listTransportors()
  }

  selectTransportor(event: any) {
    if (event.target.value == "Selectionnez le transporteur") {
      this.userId = null;
    } else {
      this.userId = event.target.value;
    }
  }

  async listTransportors() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
    });
  }

  async inventaire(datein, datefin) {
    if (datein.value != "" && datefin.value != "" && this.userId != null && this.userId != "null") {
      await this.trackingServices.statisticsTransportors(datein.value, datefin.value, this.userId).then((res: any) => {
        // console.log("res statisticsCmds : ")
        // console.log(res)
        if (res) {
          this.statsGroupings = res;
        }
      });
    } else {
      alert('Veuillez remplir les champs obligatoires !');
      this.statsGroupings = {}
      this.cmdList = []
    }
  }

  displayCmdsByStatus(status) {
    if (status == "validated") {
      this.etatGrouping = "validée"
      this.cmdList = this.statsGroupings.allGroupingValidated
    } else if (status == "pick_uped") {
      this.etatGrouping = "Commande récupérée"
      this.cmdList = this.statsGroupings.allGroupingDepot
    } else if (status == "transporting") {
      this.etatGrouping = "transportée"
      this.cmdList = this.statsGroupings.allGroupingTransporting
    } else if (status == "delivered") {
      this.etatGrouping = "livrée"
      this.cmdList = this.statsGroupings.allGroupingLivred
    } else if (status == "paied") {
      this.etatGrouping = "payé"
      this.cmdList = this.statsGroupings.allGroupingPaied
    } else if (status == "returned") {
      this.etatGrouping = "retournée"
      this.cmdList = this.statsGroupings.allGroupingReturned
    } else if (status == "canceled") {
      this.etatGrouping = "annulée"
      this.cmdList = this.statsGroupings.allGroupingCanceled
    } else {
      this.cmdList = []
    }
  }

  async exportToExcel(datein, datefin) {
    let userTrans = await this.getUserTracking()

    let reportData = {
      title: 'Statistique des commandes',
      userTrans: userTrans,
      data: this.cmdList,
      headers: ["#", "Référence", "Date de Création",
        "Nom Client & Téléphone", "Localisation", "Destination",
        "Services",
        "Total Commande"], // Object.keys(this.cmdList[0])
      etatCmd: this.etatGrouping,
      startDate: datein.value,
      endDate: datefin.value
    }

    this.trackingServices.exportExcel(reportData);
  }

  getUserTracking() {
    let promise = new Promise<any>(async (resolve, reject) => {
      this.trackingServices.getOneUserTracking(this.userId).then((user) => {
        if (user) {
          resolve(user)
        } else
          resolve(null)
      });
    });
    return promise
  }

  async exportAllCmdsToExcel(datein, datefin) {
    let userTrans = await this.getUserTracking()

    if (userTrans != null && userTrans != null && datein.value != "" && datefin.value != ""
      && this.etatGrouping != "" && this.statsGroupings != null && this.statsGroupings.allGrouping != null
      && this.statsGroupings.allGrouping.length > 0) {
      let reportData = {
        title: 'Statistique des commandes',
        userTrans: userTrans,
        data: this.statsGroupings.allGrouping,
        headers: ["#", "Référence", "Date de Création",
          "Nom Client & Téléphone", "Localisation", "Destination",
          "Services",
          "Total Commande"], // Object.keys(this.cmdList[0])
        etatCmd: this.etatGrouping,
        startDate: datein.value,
        endDate: datefin.value
      }

      this.trackingServices.exportExcel(reportData);
    } else {
      if (userTrans == null || userTrans == "null" || datein.value == "" || datefin.value == "") {
        alert('Veuillez remplir les champs obligatoires !');
      } else if(this.statsGroupings == null || this.statsGroupings.allGrouping == null
        || (this.statsGroupings.allGrouping != null && this.statsGroupings.allGrouping.length == 0)) {
          alert('La liste des commandes est vide !');
      } else if(this.etatGrouping == "") {
          alert("L'état' des commandes est vide !");
      }

    }
  }

}

