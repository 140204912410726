<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content>
    <h2 style="text-align:center">Vous allez sélectionner le chauffeur</h2>
    <div class="div-form">
        <div class="form-group">
            <legend for="proposition" class="space-bottom">Chauffeur
            </legend>
            <select class="form-control selectric" (change)="selectChangeHandler($event)" [(ngModel)]="uservalue">
                <option [value]="null">Selectionner un chauffeur</option>
                <option [ngValue]="user" *ngFor="let user of leadersList">
                    {{user?.user_transportor?.prenom_user}} {{user?.user_transportor?.nom_user}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <legend for="proposition" class="space-bottom">Proposition
            </legend>
            <input type="number" min="1" class="form-control" name="proposition" [(ngModel)]="data.proposition">
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <button
    [mat-dialog-close]="false" class="btn btn-info">NON</button>

    <button *ngIf="data.user != null && data.user != 'null' && data.proposition != null && data.proposition != ''" 
        style="margin-right: 15px;" 
    [mat-dialog-close]="true" 
    class="btn btn-danger">OUI</button>
</div>
