import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  allServices: any = []

  constructor(private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this._fetchServices()
  }

  async _fetchServices() {
    // window.scrollTo(0, 380);
    await this.cmdsService.allServices().then((res: any) => {
      if (res) {
        this.allServices = res;
      }
    });
  }

  async _visibleService(idFamily) {
    // window.scrollTo(0, 380);
    await this.cmdsService.visibleServices(idFamily).then((res: any) => {
      if (res) {
        this.allServices = res;
      }
    });
  }

}
