<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Inventaire
                <div class="page-title-subheading">Faire un <strong>inventaire</strong> sur les ventes
                    et les en-cours.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="inventaire(datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            #datein ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="datein.value==''">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            #datefin ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="datefin.value==''">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric"
                                            (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="client" class="">Client</legend>
                                        <select class="form-control selectric" (change)="selectClient($event)">
                                            <option value="Selectionnez le client">
                                                Selectionnez le client</option>
                                            <option [value]="cl.user_transportor._id" *ngFor="let cl of clientsList">
                                                {{cl?.user_transportor?.prenom_user + " " +
                                                cl?.user_transportor?.nom_user}}</option>
                                        </select>
                                        <!-- <mat-error style="color: red;font-weight: bold;"
                                            *ngIf="userId==null || userId=='null'">
                                            Cette question est obligatoire.
                                        </mat-error> -->
                                    </div>
                                </div>
                                <button type="submit" (click)="inventaire(datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                                <!-- <div class="col-md-1">
                                        <div class="position-relative form-group">
                                            <legend for="firstName" class="">dd</legend>
                                            <button class="mb-2 mr-2 btn btn-primary">
                                                <i class="nav-link-icon pe-7s-search"></i>
                                            </button>
                                        </div>
                                    </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="d-block text-center card-footer">
                <div class="clearfix mb-3"></div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('created')">
                            <div class="card-stats-item-count">
                                {{statsGroupings?.allGroupingPending?.length}}</div>
                            <div class="card-stats-item-label">En attente</div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('validated')">
                            <div class="card-stats-item-count">
                                {{statsGroupings?.allGroupingValidated?.length}}</div>
                            <div class="card-stats-item-label">Validée</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('pick_uped')">
                            <div class="card-stats-item-count">{{statsGroupings.allGroupingDepot?.length}}
                            </div>
                            <div class="card-stats-item-label">Au dépôt</div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('transporting')">
                            <div class="card-stats-item-count">
                                {{statsGroupings?.allGroupingTransporting?.length}}</div>
                            <div class="card-stats-item-label">En cours<br>de transport</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('delivered')">
                            <div class="card-stats-item-count">{{statsGroupings?.allGroupingLivred?.length}}
                            </div>
                            <div class="card-stats-item-label">Livrée</div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('paied')">
                            <div class="card-stats-item-count">{{statsGroupings?.allGroupingPaied?.length}}
                            </div>
                            <div class="card-stats-item-label">Payée</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('returned')">
                            <div class="card-stats-item-count">{{statsGroupings?.allGroupingReturned?.length}}
                            </div>
                            <div class="card-stats-item-label">Retournée</div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('canceled')">
                            <div class="card-stats-item-count">{{statsGroupings?.allGroupingCanceled?.length}}
                            </div>
                            <div class="card-stats-item-label">Annulée</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix mb-3"></div>

            <div class="row">
                <div class="col-md-6 col-xl-4">
                    <div class="widget-content">
                        <div class="widget-content-wrapper text-black">
                            <div class="widget-content-left mr-3">
                                <div class="icon-wrapper rounded-circle">
                                    <div class="icon-wrapper-bg bg-primary"></div>
                                    <i class="pe-7s-display1 text-back"></i>
                                </div>
                            </div>
                            <div class="widget-content-left">
                                <div class="widget-heading">Total des commandes</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-black"><span>{{statsGroupings?.allGrouping?.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-3"></div>

                <div class="col-md-6 col-xl-5">
                    <div class="widget-content">
                        <div class="widget-content-wrapper text-black">
                            <div class="widget-content-left mr-3">
                                <div class="icon-wrapper rounded-circle">
                                    <div class="icon-wrapper-bg bg-primary"></div>
                                    <i class="pe-7s-cash text-back"></i>
                                </div>
                            </div>
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Prix <br>des commandes livrées</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-black"><span>{{statsGroupings?.totalLivredCmds}}
                                        DT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                            <h2 class="text-center">Pas de commande</h2>
                        </div>
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="cmdList?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Client</th>
                                    <th class="text-left">Référence</th>
                                    <th class="text-center">Date<br>de<br>création</th>
                                    <th class="text-center">Localisation</th>
                                    <th class="text-center">Destination</th>
                                    <th class="text-center">Total Commande</th>
                                    <th class="text-center">
                                        Services</th>
                                    <th class="text-center">Statut</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of cmdList, let index = index">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                        <ngx-avatar class="rounded-circle" name="{{cmd.user_transportor.prenom_user}}
                                                {{cmd.user_transportor.nom_user}}">
                                                        </ngx-avatar>
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">{{cmd.user_transportor.prenom_user}}
                                                        {{cmd.user_transportor.nom_user}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_transportor.email_user}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_transportor.tel_user}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text">{{cmd.ref_grouping}}</td>
                                    <td class="text-center">{{cmd.creation_date_grouping | date:'d MMM y'}}<br>
                                        <b>{{cmd.creation_date_grouping | date:'HH:mm'}}</b>
                                    </td>
                                    <td class="text-center">{{cmd?.user_location?.main_target}}<br>
                                        <strong>{{cmd?.user_location?.sencondary_target}}</strong>
                                    </td>
                                    <td class="text-center">{{cmd?.user_destination?.main_target}}<br>
                                        <strong>{{cmd?.user_destination?.sencondary_target}}</strong>
                                    </td>
                                    <td class="text-center">{{cmd.price |
                                        number:'0.3'}} DT</td>
                                    <td class="text-center">
                                        <strong>{{cmd?.palette?.name}}</strong><br>
                                        <strong>{{cmd?.sub_family?.family?.name}}</strong><br>
                                        <strong>{{cmd?.sub_family?.name}}</strong><br>
                                        <strong>Quantité: {{cmd?.quantity}}</strong>
                                    </td>
                                    <td class="text-center">
                                        <div class="badge badge-{{statusCmds[cmd.etat_grouping].color}}">
                                            {{statusCmds[cmd.etat_grouping].label}}</div>
                                    </td>
                                    <!-- <td class="text-center">
                                        <button [routerLink]="['/detail-cmd']"
                                            [queryParams]="{ cmd: cmd._id }" type="button"
                                            id="PopoverCustomT-1"
                                            class="btn btn-primary btn-sm">Details</button>
                                        <button [routerLink]="['/facture']"
                                            [queryParams]="{ cmd: cmd._id }" type="button"
                                            id="PopoverCustomT-1"
                                            class="btn btn-primary btn-sm">Facture</button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-block text-center card-footer"
                        *ngIf="cmdList?.length > 0 && userId != null">
                        <button class="mr-2 btn-icon btn-icon-only btn btn-outline-success" 
                        (click)="exportToExcel(datein, datefin)"><i
                                class="pe-7s-download btn-icon-wrapper"> Exporter la statistique</i></button>
                        <!-- <button class="btn-wide btn btn-success">Exporter</button> -->
                    </div>
                    <!-- <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="previousPage(ref, numero, datein, datefin)">
                                        <i class="nav-link-icon pe-7s-angle-left-circle"></i> Précédent</a>
                                </li>

                                <div *ngFor="let p of pagesDisplaied">
                                    <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                        <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                        <span *ngIf="currentPage===p" class="page-link">
                                            {{p+1}}
                                            <span class="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </div>

                                <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="nextPage(ref, numero, datein, datefin)">Suivant <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>