import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { TeamsService } from '../teams-founix/teams.service';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging,
    private teamService: TeamsService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging: any) => {
        // _messaging.setBackgroundMessageHandler((payload) => {
        //   console.log("payload ::::: " + payload)
        // });
        // _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
        // _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      async (token) => {
        // console.log(token);
        await this.teamService.saveToken(token)
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // console.log("new message received. ", payload);
        this.currentMessage.next(payload);
        this.showCustomNotification(payload)
      },
      (err) => {
        console.error('Unable to get received message. ', err);
      })
  }

  showCustomNotification(payload: any) {
    let notify_data = payload['notification']
    let data_action = payload['data']
    let title = notify_data['title']
    let options = {
      body: notify_data['body'],
      icon: "../../../assets/images/logo-phoenix-black.png",
      badge: "../../../assets/images/logo-phoenix-black.png",
      image: "../../../assets/images/logo-phoenix-black.png"
    }
    console.log("new message received. ", notify_data)
    let notify: Notification = new Notification(title, options)

    notify.onclick = event => {
      event.preventDefault()
      location.href = data_action["action"] + '?' + data_action["params"]
    }
  }
}