// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var baseURLAPI = 'https://logistic.marketbey.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Wassali/";

var assetsFounixBaseURL = "https://assets.marketbey.tn/Founix/";

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyD6__GslDy1meeSAuPFv85XLB582mmYj7Y",
    //  databaseURL: "config data from general tab",
    authDomain: "bophoenix-61d4c.firebaseapp.com",
    projectId: "bophoenix-61d4c",
    storageBucket: "bophoenix-61d4c.appspot.com",
    messagingSenderId: "348432487826",
    appId: "1:348432487826:web:d9d4664029b67791af1d4b",
    measurementId: "G-LBQELFD5XB"
  },

  transportorUsersAssetsBaseURL: assetsFounixBaseURL + "coursiers/",
  plannersUsersAssetsBaseURL: assetsFounixBaseURL + "planners/",

  // AUTH Teams WASSALI
  apiUsersAuth: baseURLAPI + "founix/auth-teams-founix",
  apiAllTeams: baseURLAPI + "founix/all-teams",
  apiInfosUser: baseURLAPI + "founix/infos",
  apiAddUserPH: baseURLAPI + "founix/add-user-phoenix",
  apiSaveTokenPH: baseURLAPI + "founix/savetoken",


  // BO FONIX
  apiStatusGroupage: baseURLAPI + 'founix/liststatus',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'founix/cmds-status-client',
  apiSearchCmd: baseURLAPI + 'founix/search',
  apiFilterCmds: baseURLAPI + 'founix/filter-cmds',
  apiOneCmd: baseURLAPI + 'founix/grouping',
  apiOneGrouping: baseURLAPI + 'founix/one-grouping',
  apiAllCmdsByStatus: baseURLAPI + 'founix/cmds-all-status',
  apiUpdateCmdStatus: baseURLAPI + 'founix/update-grouping',
  apiPendingDemandes: baseURLAPI + 'founix/pending-grouping',

  // SERVICES
  apiAllPalets: baseURLAPI + 'founix/types-palettes',
  apiAllServices: baseURLAPI + 'founix/all-types-services',
  apiOneService: baseURLAPI + 'founix/one-family',
  apiAddServices: baseURLAPI + 'founix/add-family',
  apiEditService: baseURLAPI + 'founix/edit-family',
  apiVisibleServices: baseURLAPI + 'founix/visible-family',

  apiAllSubServices: baseURLAPI + 'founix/all-types-sub-services',
  apiVisibleSubServices: baseURLAPI + 'founix/visible-sub-family',
  apiOneSubService: baseURLAPI + 'founix/one-sub-family',
  apiAddSubServices: baseURLAPI + 'founix/add-sub-family',
  apiEditSubServices: baseURLAPI + 'founix/edit-sub-family',

  // DEMANDES
  apiStatusDemandes: baseURLAPI + 'transportor/liststatus',
  apiAllCmdsByStatusPH: baseURLAPI + 'transportor/cmds-all-status-ph',
  apiStatisticsFluxCmds: baseURLAPI + 'transportor/statistics-flux-demandes',
  apiAllDemandesByStatusAndClient: baseURLAPI + 'transportor/demandes-status-client',
  apiTypeCamion: baseURLAPI + 'transportor/type-camions',
  apiGetServices: baseURLAPI + 'transportor/get-services',
  apiAllDemandes: baseURLAPI + 'transportor/demandes-ph',
  apiOneDemande: baseURLAPI + 'transportor/get-demande',
  apiAllTansportors: baseURLAPI + 'transportor/driver-transportors-ph',
  apiFilterMarkersTansportors: baseURLAPI + 'transportor/filter-markers-driver-ph',
  apiBlockedTansportor: baseURLAPI + 'transportor/block-transportor',
  apiDeblockedTansportor: baseURLAPI + 'transportor/deblock-transportor',
  apiValidateTansportor: baseURLAPI + 'transportor/validate-transportor',
  apiOneTansportor: baseURLAPI + 'transportor/details-transportor',

  apiResponseDemande: baseURLAPI + 'transportor/response-demande',
  apiAccepteDemande: baseURLAPI + 'transportor/accepte-demande',

  // USER
  apiAddUsersTracking: baseURLAPI + 'founix/subscribe',
  apiGetUsersTracking: baseURLAPI + 'founix/user-responsable',
  apiGetOneUserTracking: baseURLAPI + 'founix/get-users-tracking',
  apiPaiedCmdsByTransporter: baseURLAPI + 'founix/paied-cmds-transporter',
  apiValidateReturnedCmds: baseURLAPI + 'founix/validate-return-cmds',
  apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress: baseURLAPI + 'founix/details-cmds-tracking',
  apiAffectTransport: baseURLAPI + 'founix/affect-transport',
  apiGetPreparers: baseURLAPI + 'founix/preparers',
  apiAffectPrepaToStore: baseURLAPI + 'founix/affect-prepa-store',
  apiStatsTransportor: baseURLAPI + 'founix/statistics-transportor',
  apiStatsCmds: baseURLAPI + 'founix/statistics-cmds',


  // CONS
  apiAllCons: baseURLAPI + 'founix/list-cons',
  apiGenerateCons: baseURLAPI + 'founix/generate-cons',

  // API STORES
  apiAddStore: baseURLAPI + 'founix/add-store',
  apiAllDetailStores: baseURLAPI + 'founix/details-stores',
  apiAllDisplayStores: baseURLAPI + 'founix/display-stores',
  apiAllStores: baseURLAPI + 'founix/stores',
  apiAllStore: baseURLAPI + 'founix/store',

  // API STORES
  apiAddVehicule: baseURLAPI + 'founix/add-vehicule',
  apiAllVehicules: baseURLAPI + 'founix/vehicules',

  // API CLIENTS PHOENIX
  apiAddSociety: baseURLAPI + 'founix/add-client-phoenix',
  apiAllSocieties: baseURLAPI + 'founix/all-clients-phoenix',
  apiAffectVehToTrans: baseURLAPI + 'founix/affect-vehicule-trans',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
