import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logoFile from '../logo.js';
import { DatePipe, DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient,
    private router: Router,
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe) { }

  exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title
    const userTrans = excelData.userTrans
    const header = excelData.headers
    const data = excelData.data;
    const etatCmd = excelData.etatCmd;
    const startDate = excelData.startDate;
    const endDate = excelData.endDate;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');


    //Add Row and formatting
    worksheet.mergeCells('C1', 'G5');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 22,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells('D7', 'F9');
    let transportorRow = worksheet.getCell('D7');
    transportorRow.value = userTrans?.nom_prenom_tracking + ' - [ ' + userTrans?.profil_tracking + ' ]\nNuméro de téléphone: ' + userTrans?.numTel_tracking
    transportorRow.font = {
      name: 'Calibri',
      size: 18,
      // underline: 'single',
      bold: true,
      color: { argb: '000000' }
    }
    transportorRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    worksheet.mergeCells('H1:H5');
    let d = new Date();
    let date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('H1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    let myLogoImage = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.mergeCells('A2:B4');
    worksheet.addImage(myLogoImage, 'A2:B4');

    //Blank Row 
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 40;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.addRow([]);

    let subTitleRow = worksheet.addRow(['La liste des commandes ' + etatCmd + '(s) - de ' + startDate + " jusqu'au " + endDate]);
    subTitleRow.getCell(1).font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    };
    subTitleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    // subTitleRow.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    //Merge Cells
    worksheet.mergeCells(`A${subTitleRow.number}:H${subTitleRow.number}`);

    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    let index = 0
    let total = 0
    data.forEach(cmd => {
      // console.log(cmd)
      index++;
      total += cmd.price
      let row = worksheet.addRow([index.toString(),
      cmd?.ref_grouping,
      this._datePipe.transform(cmd?.creation_date_grouping, "d MMM y h:mm"),
      cmd?.user_transportor?.prenom_user + " " + cmd?.user_transportor?.nom_user + " - " + cmd.user_transportor.tel_user,
      cmd?.user_location?.main_target,
      cmd?.user_destination?.main_target,
      cmd?.palette?.name + "\n" + cmd?.sub_family?.family?.name + "\n" + cmd?.sub_family?.name + "\nQuantité: " + cmd?.quantity,
      this._decimalPipe.transform((cmd.price), "0.3") + " DT"
      ]);
      // let row = worksheet.addRow(d);

      let cities = row.getCell(7);
      cities.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }
      cities.alignment = { vertical: 'middle', horizontal: 'center' }

      let references = row.getCell(2);
      references.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }

      let sales = row.getCell(8);
      let color = 'FF99FF99';
      // if (+sales.value < 200000) {
      //   color = 'FF9999'
      // }
      sales.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        size: 12
      }
      sales.alignment = { vertical: 'middle', horizontal: 'right' }
      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );

    // worksheet.getColumn(3).width = 20;
    let endArray = worksheet.addRow([]);

    let totalCell = worksheet.getCell(`H${endArray.number + 1}`);
    totalCell.value = this._decimalPipe.transform(total, "0.3") + " DT"
    totalCell.font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    }
    totalCell.alignment = { vertical: 'middle', horizontal: 'right' }
    totalCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF99FF99' }
    }
    totalCell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    // //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Statistiques__Transporteur__" + etatCmd + "__" + startDate + "_" + endDate + '.xlsx');
    })

  }

  statisticsTransportors(startDate, endDate, transportor) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsTransportor, {
        transportor,
        startDate,
        endDate
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  subscribe(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddUsersTracking, formData).subscribe(
        (data: any) => {
          // console.log("dataaaaa")
          // console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  async getPreparers() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetPreparers, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  paiedCmdByTransporter(idCmd) {
    // console.log("refreshCmdsLivredCmds")
    return new Promise((slv) => {
      this.http
        .post(environment.apiPaiedCmdsByTransporter, {
          cmd: idCmd
        })
        .subscribe(
          (data: any) => {
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  validateReturnedCmdsByTransporter(idCmd, returned_by) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiValidateReturnedCmds, {
          cmd: idCmd,
          returned_by: returned_by
        })
        .subscribe(
          (data: any) => {
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }
  getOneUserTracking(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiGetOneUserTracking + "/" + id, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async allCmdsTrackingLivredReturnedAndInProgress(idUser) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress,
        { user: idUser }).subscribe(
          (data: any) => {
            // console.log("apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress")
            // console.log(data)
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }


  goToNewTab(idLivreur, route) {
    const queryParams = {
      userTracking: idLivreur
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  async getResponsables() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetUsersTracking, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addVehicule(vehiculeName) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddVehicule, {
        vehiculeName: vehiculeName
      }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  affectTransToVehicule(idTrans, idVehicule) {
    return new Promise((slv) => {
      this.http.post(environment.apiAffectVehToTrans, {idVehicule: idVehicule,
        idTrans: idTrans}).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  async getVehicules() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllVehicules, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

}
