import { Component, OnInit } from '@angular/core';
import { DemandesTransService } from 'src/app/services/demandes-trans/demandes-trans.service';

@Component({
  selector: 'app-list-transportors-ph',
  templateUrl: './list-transportors-ph.component.html',
  styleUrls: ['./list-transportors-ph.component.css']
})
export class ListTransportorsPhComponent implements OnInit {

  allTrans: any = [];
  allTransValid: any = [];
  allTransNotValid: any = [];
  nbrAllTrans: number = 0
  nbrTransHorsLine: number = 0
  nbrTransPending: number = 0
  tagTransportor: string = "Tous les transporteurs"

  constructor(private transServices: DemandesTransService,) { }

  ngOnInit(): void {
    this.listTransportors()
  }

  draftTransportor(idDrive) {
    // this.transServices.draftTansportor(idDrive).then(async (data: any) => {
    //   console.log("dddddddddddddd data")
    //   console.log(data)
    //   if (data && data.response) {
    //     await this.listTransportors()
    //   } else {
    //     alert(data.msg);
    //   }
    // });
  }

  async listTransportors() {
    this.tagTransportor = "Tous les transporteurs"
    await this.transServices.getTransportors().then((res: any) => {
      if (res) {
        // console.log("ressssssssssssss")
        // console.log(res)
        this.allTrans = res;
        this.nbrAllTrans = this.allTrans.length
        this.allTransValid = this.filterTransportor(true)
        this.nbrTransHorsLine = this.getTransHorsLine(this.allTransValid)
        this.nbrTransPending = this.getTransPending(this.allTrans)
        this.allTransNotValid = this.filterTransportor(false)
      }
    });
  }

  getTransHorsLine(arrayTrans) {
    var array= arrayTrans.filter(trans => trans.onLine == false);
    return array.length
  }
  getTransPending(arrayTrans) {
    var array= arrayTrans.filter(trans => (trans.preteToValidate!=null && trans.preteToValidate == true));
    return array.length
  }

  filterTransportor(isValid, notblocked?) {
    return this.allTrans.filter(trans => trans.isValidate == isValid && trans.is_blocked == false);
  }

  isValidTransportor() {
    this.tagTransportor = "Transporteur validé"
    this.allTrans = this.allTransValid
  }

  isNotValidTransportor() {
    this.tagTransportor = "Transporteur non validé"
    this.allTrans = this.allTransNotValid
  }

  onLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == true);
  }

  offLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == false);
  }

  pendingTrans() {
    this.allTrans = this.allTrans.filter(trans => (trans.preteToValidate!=null && trans.preteToValidate == true));
  }

}
