import { Component, OnInit } from '@angular/core';
import { StoresService } from 'src/app/services/stores/stores.service';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.css']
})
export class AddStoreComponent implements OnInit {

  villes = [
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef', 
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan',];
  selectedVille: any = null;

  constructor(private storesService: StoresService) { }

  ngOnInit(): void {
  }

  async addStore(storeName) {
    if (this.selectedVille != '' && storeName.value!=null && storeName.value!="") {
      await this.storesService.addStore(storeName.value, this.selectedVille)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            location.href = '/stores';
            //this.sacsList = res;
          }
        });
    } else {
      alert("Il faut ajouter les champs obligatoire !!!")
    }
  }

}
