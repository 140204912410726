import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';
import { StoresService } from 'src/app/services/stores/stores.service';
import { UsersService } from 'src/app/services/users-founix/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grouppage',
  templateUrl: './grouppage.component.html',
  styleUrls: ['./grouppage.component.css']
})
export class GrouppageComponent implements OnInit {

  dataCmds: any = {}

  leadersList: any = [];
  storesList: any = [];
  env: any;

  constructor(private cmdsService: GoupingService,
    private trackingServices: UsersService,
    private storesService: StoresService) {
  }

  ngOnInit(): void {
    this.env = environment

    this.listCmdsByStatus()
    this.listStores()
    this.listLeaders()
  }

  async listCmdsByStatus() {
    //let userId = await this.clientService.getIdUser()
    this.cmdsService.allCmdsByStatus(null).then((res: any) => {
      // console.log("res")
      // console.log(res)
      if (res) {
        this.dataCmds = res;
      }
    });
  }

  async listStores() {
    await this.storesService.getDisplayStores().then((res: any) => {
      if (res) {
        // console.log("listStores res");
        // console.log(res);
        this.storesList = res;
      }

    });
  }

  async listLeaders() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }

    });
  }

}
