import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css']
})
export class RechercheComponent implements OnInit {

  listCmds: any = []
  statusCmd: any = []

  constructor(private cmdsService: GoupingService,
    ) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmd = lst
      }
    )
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  async listCmdsClient(keyword) {
    if (keyword.value != "") {
      await this.cmdsService.searchCmd(null, keyword.value).then((res: any) => {
        // console.log(res)
        if (res) {
          this.listCmds = res;
        }
      });
    }
  }

}
