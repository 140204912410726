import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';
import { UsersService } from 'src/app/services/users-founix/users.service';

@Component({
  selector: 'app-grouping-tracking-user',
  templateUrl: './grouping-tracking-user.component.html',
  styleUrls: ['./grouping-tracking-user.component.css']
})
export class GroupingTrackingUserComponent implements OnInit {

  idUser: string = '';
  userTracking: any = null;

  statusCmds: any = [];
  tagCmds: string = "LIVREE";
  listCmds: any = []

  cmdsLivred: any = []
  cmdsReturned: any = []
  cmdsInProgress: any = []


  totalCmdsReturned: any = 0
  totalCmdsLivred: any = 0;
  quantitiesCmdsLivred: any = 0;
  // fraisLivred: any = 0
  // fraisReturned: any = 0


  currentDate = new Date();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private trakingServices: UsersService,
    private cmdsServices: GoupingService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    this.cmdsServices.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    await this.route.queryParams.subscribe(async (params) => {
      this.idUser = params.idUser;
      this.getUserTracking()

      // this.cmdsDelivered = await this.trakingServices.cmdsLivredByTransporter(this.idUser)
      // this.fraisLivred = await this.cmdsDelivered.length * 5
      // this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsDelivered)

      // this.cmdsReturned = await this.trakingServices.cmdsReturnedByTransporter(this.idUser)
      // this.fraisReturned = await this.cmdsReturned.length * 5
      // this.totalCmdsReturned = await this.totalCmdsByStatus(this.cmdsReturned)

      // this.cmdsEnCours = await this.listCmdsUser(["transporting", "transported", "arrived"])

      ///// this.getPriceLivred()
      ///// this.getPriceAffected()

      // this.fetchCmdsAffected();
      await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()

    });
    // this.listCmdsLivredByTransporter("LIVREE")
  }

  detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress() {
    this.trakingServices.allCmdsTrackingLivredReturnedAndInProgress(this.idUser).then(async (res: any) => {
      // console.log("allCmdsTrackingLivredReturnedAndInProgress .ts")
      // console.log(res)
      if (res) {
        this.cmdsLivred = res.cmdsLivred
        this.listCmds = this.cmdsLivred
        // this.fraisLivred = this.cmdsLivred.length * this.userTracking.frais_livraison
        this.cmdsReturned = res.cmdsReturned
        this.cmdsInProgress = res.cmdsInProgress
        this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
        this.quantitiesCmdsLivred = await this.quantityCmdsByStatus(this.cmdsLivred)
      }
    });
  }
  async listCmdsLivredByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsLivred

    // this.fraisLivred = this.cmdsLivred.length * this.userTracking.frais_livraison
    this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
  }
  async listCmdsReturnedByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsReturned
  }
  async listCmdsInProgressByTransporter(tagCmds) {
    this.tagCmds = tagCmds
    this.listCmds = this.cmdsInProgress
  }


  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.price
    });

    return total
  }
  async quantityCmdsByStatus(array) {
    let quantities = 0
    array.forEach(element => {
      quantities += element.quantity
    });

    return quantities
  }

  // async listCmdsUser(status) {
  // //   let cmds: any = []
  // //   var prices = 0.0
  //   return await this.cmdsServices.allCmdsUserByStatus(this.idUser, status)
  //   // cmds.map(async (cmd) => {
  //   //   prices = await prices + cmd.total_ca_cmd
  //   // })

  //   // return cmds.length
  // }

  getUserTracking() {
    this.trakingServices.getOneUserTracking(this.idUser).then((user) => {
      if (user) {
        // console.log("userrrrrrr : ")
        // console.log(user)
        this.userTracking = user;
      }
    });
  }

  async validateReturnedCmd() {
    let promise = new Promise<any>(async (resolve, reject) => {
      if (confirm('Etes vous sûre?')) {
        var isValidated: any = true
        await this.listCmds.forEach(async (cmd) => {
          isValidated = isValidated && await this.trakingServices.validateReturnedCmdsByTransporter(cmd._id, "returned_exp")
        });
        resolve(isValidated)
      }
    });
    return promise
  }
  async refreshReturnedCmds() {
    var isValidated = await this.validateReturnedCmd()
    if (isValidated == true)
      await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
  }

  async validateLivredCmds() {
    // console.log("refreshCmdsLivredCmds")
    let promise = new Promise<any>(async (resolve, reject) => {
      if (confirm('Etes vous sûre?')) {
        var isValidated: any = true
        await this.listCmds.forEach(async (cmd) => {
          isValidated = isValidated && await this.trakingServices.paiedCmdByTransporter(cmd._id)
        });
        resolve(isValidated)
      }
    });
    return promise
    // await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
  }
  async refreshCmdsLivredCmds() {
    // console.log("refreshCmdsLivredCmds")
    var isValidated = await this.validateLivredCmds()
    if (isValidated == true)
      await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
  }
  // listComments(cmd) {
  //   // window.scrollTo(0, 0);
  //   if (cmd.comments.length > 0) {
  //     const confirmDialog = this.dialog.open(ListCommentsComponent, {
  //       autoFocus: false,
  //       // panelClass: 'trend-dialog',
  //       data: {
  //         cmd: cmd,
  //       }
  //     });
  //   }
  // }

  goToNewTab(route) {
    this.trakingServices.goToNewTab(this.idUser, route)
  }

}
