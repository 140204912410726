import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-edit-sub-service',
  templateUrl: './edit-sub-service.component.html',
  styleUrls: ['./edit-sub-service.component.css']
})
export class EditSubServiceComponent implements OnInit {

  idSubFamily: string = ""
  subFamily: any = {    
    name: ""
  }

  constructor(private route: ActivatedRoute,
    private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idSubFamily = params.subFamily;
      this._fetchSubFamily();
      // console.log(this.cmd);
    });
  }

  _fetchSubFamily() {
    // console.log(cid);
    this.cmdsService.getOneSubFamily(this.idSubFamily).then((d) => {
      // console.log("dddddddddddddd")
      // console.log(d)
    
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.subFamily = d;
      } else {
        alert('Aucun service validé');
        location.href = '/services';
      }
    });
  }

  async editSubFamily() {
    await this.cmdsService.editSubService(this.idSubFamily, this.subFamily.name).then((res: any) => {
      if (res && res.response == true) {
        location.href = '/sub-services'
      }
    });
  }

}
