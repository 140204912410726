import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AffectationGroupingComponent } from 'src/app/components/shared/dialogs/affectation-grouping/affectation-grouping.component';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-status-commandes',
  templateUrl: './status-commandes.component.html',
  styleUrls: ['./status-commandes.component.css']
})
export class StatusCommandesComponent implements OnInit {

  statusCmds: Array<string> = []
  isPaied: boolean = null
  listCmds: any = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  statusCmd: any = []

  cmdsListToAffect: any = [];

  constructor(private route: ActivatedRoute,
    private cmdsService: GoupingService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.statusCmds = params.status;
      this.isPaied = params.paied
      // console.log("params.status")
      // console.log(params.status)
      // console.log("params.paied")
      // console.log(params.paied)
      this.listCmdsClient(this.statusCmds, this.isPaied)
    });
  }

  async affectMoreCmds() {
    const confirmDialog = this.dialog.open(AffectationGroupingComponent, {
      data: {
        title: 'Confirm Remove Employee',
        message: 'Supprimer un produit',
        idCmd: null,
        user: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data.user)
        var tabsCmds = []
        this.cmdsListToAffect.forEach(element => {
          // this.cmdsService.affectTransport(element._id, data.user).then((res) => {
          //   if (res) {
          tabsCmds.push(element._id)
          //   }
          // });
        });

        this.cmdsService.affectTransport(tabsCmds, data.user).then((res) => {
          if (res) {
            this.listCmdsClient(this.statusCmds, this.isPaied)
          }
        });

      }
    });
  }

  addCmdToAffect(event: any, cmd) {
    // console.log("event")
    // console.log(event)
    if (event.target.checked) {
      this.cmdsListToAffect.push(cmd)
    } else {
      //const index = this.cmdsListToAffect.controls.findIndex(x => x === cmd);
      //this.cmdsListToAffect.remove(cmd);
      this.cmdsListToAffect = this.cmdsListToAffect.filter(item => item !== cmd);
    }
    // console.log(this.cmdsListToAffect)
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  includes(array) {
    for (var i = 0; i < array.length; i++) {
      if (this.statusCmds.includes(array[i])) {
        return true
      }
    }
    return false
  }

  async listCmdsClient(status, isPaied) {
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, null, [status], isPaied, null).then((res: any) => {
      // console.log("res listCmdsClient")
      // console.log(res)
      if (res) {
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    if (confirm('Etes vous sûre?'))
      await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
        if (res) {
          alert('Commande mis à jour avec succès');
          // cmd.etat_cmd = "canceled"
          this.listCmdsClient(this.statusCmds, this.isPaied)
        }
        else {
          alert("Une erreur s'est produite");
        }
      });
  }

}
