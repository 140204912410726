import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';
import { StoresService } from 'src/app/services/stores/stores.service';

@Component({
  selector: 'app-details-store',
  templateUrl: './details-store.component.html',
  styleUrls: ['./details-store.component.css']
})
export class DetailsStoreComponent implements OnInit {

  idStore: string = null
  // tagCmds: string = "all"
  store: any = {}
  statusCmds: any = []
  listCmds: any = []

  constructor(private cmdsService: GoupingService,
    private storesService: StoresService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe(async (params) => {
      this.idStore = params.store;
      this.getStore()
    });
  }

  async getStore() {
    await this.storesService.getStore(this.idStore).then((res: any) => {
      if (res) {
        // console.log("store res");
        // console.log(res);
        // this.tagCmds = "all"
        this.store = res;
        this.listCmds.push(...res.receivedCmds)
        // this.listCmds.push(...res.inStoreCmds)
        // this.listCmds.push(...res.preparedCmds)
      }
     
    });
  }

  // selectStatus(array, tag) {
  //   // this.tagCmds = tag
  //   this.listCmds = array
  // }

  getTypeColis(cmds: any, type) {
    if(cmds != null && cmds.length > 0) {
      var array= cmds.filter(cmd => cmd.type_colis.includes(type));
      return array.length
    }
    return 0
  }

  // filterByType(type) {
  //   if(this.tagCmds == 'validated'){
  //     this.listCmds = this.store.pendingCmds.filter(cmd => cmd.type_colis.includes(type));
  //   } else if(this.tagCmds == 'transporting_depot') {
  //     this.listCmds = this.store.inStoreCmds.filter(cmd => cmd.type_colis.includes(type));
  //   } else if(this.tagCmds == 'prepared') {
  //     this.listCmds = this.store.preparedCmds.filter(cmd => cmd.type_colis.includes(type));
  //   }
    
  // }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

}
