<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-add-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Utilisateur
                <!-- <div class="page-title-subheading">Ajouter un nouveau utilisateur (TRANSPORTEUR /
                    PREPARATEUR / LOUAGISTE).
                </div> -->
                <div class="page-title-subheading">Ajouter un nouvel utilisateur (TRANSPORTEUR).
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
        </div> -->
    </div>
</div>

<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="firstName" class="">Prénom</legend>
                                    <input name="firstName" id="firstName" type="text" class="form-control" #firstName
                                        ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="firstName.value==''">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <!-- <legend for="lastName" class="">Nom</legend>
                                    <input name="lastName" id="lastName" type="text" class="form-control" #lastName
                                        ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="lastName.value==''">
                                        Cette question est obligatoire.
                                    </mat-error> -->

                                    <legend for="username" class="">Email</legend>
                                    <input name="username" id="username" type="text" class="form-control" #username
                                        ngModel>
                                    <mat-error style="color: red;font-weight: bold;" *ngIf="username.value=='' 
                                        || (username.value!='' && !username.value.includes('@'))">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <div class="Neon Neon-theme-dragdropbox">
                                        <input style="z-index: 999; opacity: 0; width: 320px; height: 200px; position: absolute; right: 0px; 
                    left: 0px; margin-right: auto; margin-left: auto;" name="file" id="file" #file accept="image/*"
                                            (change)="handleFileInput($event.target.files)" type="file">
                                        <div class="Neon-input-dragDrop">
                                            <div class="Neon-input-inner">
                                                <div class="Neon-input-icon" *ngIf="imageUrl==''">
                                                    <i class="fa fa-file-image-o"></i>
                                                </div>
                                                <div class="Neon-input-icon" *ngIf="imageUrl!=''">
                                                    <img [src]="imageUrl" style="width:100px">
                                                </div>
                                                <div class="Neon-input-text">
                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                </div><a class="Neon-input-choose-btn blue">Parcourir</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="imageUrl==''">Ajouter
                                        la photo de profile
                                    </mat-error> -->
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">

                                    <div class="col-12 form-group">
                                        <legend for="password" class="">Mot de passe</legend>
                                        <input name="password" id="password" type="password" class="form-control"
                                            #password ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="password.value==''">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>

                                    <div class="col-12 form-group">
                                        <legend for="confirmPassword" class="">Confirmer mot de passe</legend>
                                        <input name="confirmPassword" id="confirmPassword" type="password"
                                            class="form-control" #confirmPassword ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="confirmPassword.value=='' 
                                            || (confirmPassword.value!='' && confirmPassword.value!=password.value)">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>

                                    <div class="col-6 form-group">
                                        <legend for="numTel" class="">Numéro téléphone</legend>
                                        <input name="numTel" id="numTel" type="number" class="form-control" #numTel
                                            ngModel>
                                        <mat-error style="color: red;font-weight: bold;" *ngIf="numTel.value=='' 
                                                || (numTel.value!='' && numTel.value.length!=8)">
                                            Cette question est obligatoire.
                                        </mat-error>
                                    </div>

                                    <div class="col-6 form-group">
                                        <legend for="numTel2" class="">Numéro téléphone 2</legend>
                                        <input name="numTel2" id="numTel2" type="number" class="form-control" #numTel2
                                            ngModel name="numTel2">
                                        <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="numTel2.value=='' 
                                                    || (numTel2.value!='' && numTel2.value.length!=8)">
                                            Cette question est obligatoire.
                                        </mat-error> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="form-group">
                                    <legend for="profile" class="">Profil</legend>
                                    <select class="form-control" [(ngModel)]="selectedProfiles" name="profile">
                                        <option [value]="profile" *ngFor="let profile of profiles">
                                            {{profile}}</option>
                                    </select>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="selectedProfiles.length == 0">
                                        Cette question est obligatoire.
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-6" *ngIf="isProfileIncludes('RECEPTION')">
                                <legend for="Station" class="">Dépôt</legend>
                                <select class="form-control" (change)="selectStore($event)">
                                    <option>Selectionnez la station</option>
                                    <option value="{{store._id}}" *ngFor="let store of storesList">
                                        {{store.store_name}}</option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;" *ngIf="idStore=='Selectionnez le dépôt' 
                                            || idStore==null">
                                    Cette question est obligatoire.
                                </mat-error>
                            </div>
                        </div>


                        <!-- <div *ngIf="isProfileIncludes('DISPATCH')">
                            <div class="position-relative row form-group">
                                <legend for="exampleSelect" class="col-sm-2 col-form-label">Stations</legend>
                                <div class="col-sm-10">
                                    <select class="form-control" (change)="selectStore($event)">
                                        <option>Selectionnez la station</option>
                                        <option value="{{store._id}}" *ngFor="let store of storesList">
                                            {{store.store_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->



                        <div class="form-group">
                            <button type="submit"
                                (click)="addUser(firstName, username, password, confirmPassword, numTel, numTel2)"
                                class="btn btn-primary btn-lg btn-block">
                                Ajouter un utilisateur
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>