<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-ribbon icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Familles
                <div class="page-title-subheading">La liste des familles.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-warning" [routerLink]="['/add-family']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Ajouter une nouvelle famille
                </button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Les familles
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="allServices?.length === 0">
                        <h5 class="text-center">Aucun service créé</h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="allServices?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>Famille</th>
                                <th class="text-center">Etat</th>
                                <th class="text-center" data-width="100">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let serv of allServices, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>{{serv?.name}}</td>
                                <td class="text-center">
                                    <div
                                        [class]="(serv?.etat_family==false) ? 'badge badge-danger' : 'badge badge-success'">
                                        {{(serv?.etat_family==false) ? 'Invisible' : 'Visible'}}</div>
                                </td>
                                <td class="text-center">
                                    <a title="Modifier" [routerLink]="['/edit-family']"
                                        [queryParams]="{ family: serv._id }"
                                        class="btn btn-info btn-action trigger--fire-modal-1"><i
                                            class="fas fa-pencil-alt"></i></a>
                                    <a (click)="_visibleService(serv._id)" title="Supprimer"
                                        class="btn btn-action trigger--fire-modal-1"
                                        [class]="(serv?.etat_family==true) ? 'btn-success' : 'btn-danger'">
                                        <i [class]="(serv?.etat_family==true) ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>