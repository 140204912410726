<i class="material-icons" style="font-size: 100px;color: green">done</i>
<div mat-dialog-content>
    <h1 style="text-align:center"><strong></strong></h1>
    <h2 style="text-align:center">Veuillez sélectionner le transporteur</h2>
    <div class="div-form">
        <div class="form-group">
            <select class="form-control selectric" (change)="selectChangeHandler($event)">
                <option [value]="null">Selectionner un transporteur</option>
                <option [value]="user.element._id" *ngFor="let user of leadersList">{{user.element.nom_prenom_tracking}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <button [mat-dialog-close]="false" class="btn btn-info">NON</button>

    <button style="margin:0px 10px;" [mat-dialog-close]="true" class="btn btn-danger"
        *ngIf="data.user!=null && data.user!='null'">OUI</button>
</div>