import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-detail-grouping',
  templateUrl: './detail-grouping.component.html',
  styleUrls: ['./detail-grouping.component.css']
})
export class DetailGroupingComponent implements OnInit {
  
  cmd: any = {}
  cmdID: string = '';
  statusCmds: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getOneCMD(this.cmdID).then((d) => {
      // console.log("dddddddddddddd")
      // console.log(d)
    
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.cmd = d;
      } else {
        alert('Aucune commande validée');
        location.href = '/commandes';
      }
    });
  }

}
