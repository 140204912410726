import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { LoginComponent } from './login/login.component';
import { AddClientComponent } from './pages/clients/add-client/add-client.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { StatisticsCommandesComponent } from './pages/clients/statistics-commandes/statistics-commandes.component';
import { AllCommandesComponent } from './pages/dashboard/all-commandes/all-commandes.component';
import { AllDemandesComponent } from './pages/dashboard/all-demandes/all-demandes.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { GrouppageComponent } from './pages/dashboard/grouppage/grouppage.component';
import { RechercheDemandesComponent } from './pages/dashboard/recherche-demandes/recherche-demandes.component';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { ReturnedCommandesComponent } from './pages/dashboard/returned-commandes/returned-commandes.component';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { StatusDemandesComponent } from './pages/dashboard/status-demandes/status-demandes.component';
import { DetailsDemandeComponent } from './pages/demandes/details-demande/details-demande.component';
import { DetailsTransportorPhComponent } from './pages/demandes/details-transportor-ph/details-transportor-ph.component';
import { ListTransportorsPhComponent } from './pages/demandes/list-transportors-ph/list-transportors-ph.component';
import { PositionsTransportorsPhComponent } from './pages/demandes/positions-transportors-ph/positions-transportors-ph.component';
import { DetailGroupingComponent } from './pages/grouping-founix/detail-grouping/detail-grouping.component';
import { FactureGroupingComponent } from './pages/grouping-founix/facture-grouping/facture-grouping.component';
import { PendingGroupingComponent } from './pages/grouping-founix/pending-grouping/pending-grouping.component';
import { AddServiceComponent } from './pages/services/add-service/add-service.component';
import { AddSubServiceComponent } from './pages/services/add-sub-service/add-sub-service.component';
import { ConsComponent } from './pages/services/cons/cons.component';
import { EditServiceComponent } from './pages/services/edit-service/edit-service.component';
import { EditSubServiceComponent } from './pages/services/edit-sub-service/edit-sub-service.component';
import { PaletsComponent } from './pages/services/palets/palets.component';
import { ServicesComponent } from './pages/services/services/services.component';
import { SubServicesComponent } from './pages/services/sub-services/sub-services.component';
import { AddStoreComponent } from './pages/stores/add-store/add-store.component';
import { DetailsStoreComponent } from './pages/stores/details-store/details-store.component';
import { StoresComponent } from './pages/stores/stores/stores.component';
import { AddUserPhoenixComponent } from './pages/teams/add-user-phoenix/add-user-phoenix.component';
import { TeamsPhoenixComponent } from './pages/teams/teams-phoenix/teams-phoenix.component';
import { AddTransportorComponent } from './pages/users-founix/add-transportor/add-transportor.component';
import { AddVehiculeComponent } from './pages/users-founix/add-vehicule/add-vehicule.component';
import { GroupingTrackingUserComponent } from './pages/users-founix/grouping-tracking-user/grouping-tracking-user.component';
import { ReceptionsComponent } from './pages/users-founix/receptions/receptions.component';
import { StatisticsTransportorComponent } from './pages/users-founix/statistics-transportor/statistics-transportor.component';
import { TransportorsComponent } from './pages/users-founix/transportors/transportors.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      // Dashboads
      { path: '', component: DashboardComponent, canActivate: [AuthGuard], data: { extraParameter: 'dashboardsMenu' } },
      { path: 'all-demandes', component: AllDemandesComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'search-demande', component: RechercheDemandesComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'details-demande', component: DetailsDemandeComponent, data: { extraParameter: 'componentsMenu' } },
      
      { path: 'search', component: RechercheComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'status-grouping', component: StatusCommandesComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'returned-grouping', component: ReturnedCommandesComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      
      { path: 'grouppage', component: GrouppageComponent, canActivate: [AuthGuard], data: { extraParameter: 'dashboardsMenu' } },
      { path: 'all-grouping', component: AllCommandesComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-grouping', component: DetailGroupingComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'pending-grouping', component: PendingGroupingComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      
      // TEAMS PHOENIX
      { path: 'teams-phoenix', component: TeamsPhoenixComponent, canActivate: [AuthGuard], data: { roles: ["admin"] } },
      { path: 'add-user-phoenix', component: AddUserPhoenixComponent, canActivate: [AuthGuard], data: { roles: ["admin"] } },

      // DEMANDES
      { path: 'status-demandes', component: StatusDemandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'transportors', component: ListTransportorsPhComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-transportor', component: DetailsTransportorPhComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'positions-transportors', component: PositionsTransportorsPhComponent, data: { extraParameter: 'componentsMenu' } },
      
      // services
      { path: 'palets', component: PaletsComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      { path: 'cons', component: ConsComponent, canActivate: [AuthGuard], data: { extraParameter: 'componentsMenu' } },
      
      { path: 'services', component: ServicesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'add-family', component: AddServiceComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-family', component: EditServiceComponent, data: { extraParameter: 'componentsMenu' } },

      { path: 'sub-services', component: SubServicesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'add-sub-family', component: AddSubServiceComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-sub-family', component: EditSubServiceComponent, data: { extraParameter: 'componentsMenu' } },

      // USERS
      { path: 'add-user', component: AddTransportorComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'receptions', component: ReceptionsComponent, data: { extraParameter: 'componentsMenu' }/*, canActivate: [AuthGuard]*/ },
      { path: 'tracking-user', component: TransportorsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'grouping-tracking-user', component: GroupingTrackingUserComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'statistics-tranportor', component: StatisticsTransportorComponent, data: { extraParameter: 'componentsMenu' } },

      // stores
      { path: 'stores', component: StoresComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'add-store', component: AddStoreComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'details-store', component: DetailsStoreComponent, data: { extraParameter: 'componentsMenu' } },

      // vehicule
      { path: 'add-vehicule', component: AddVehiculeComponent, data: { extraParameter: 'componentsMenu' } },
      
      // CLIENTS PHOENIX
      { path: 'add-client-phoenix', component: AddClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'clients-phoenix', component: ClientsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'statistics', component: StatisticsCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      
    ]
  },

  { path: '', redirectTo: '', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },

  { path: 'facture-grouping', component: FactureGroupingComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
