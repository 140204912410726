import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-sub-services',
  templateUrl: './sub-services.component.html',
  styleUrls: ['./sub-services.component.css']
})
export class SubServicesComponent implements OnInit {

  allSubServices: any = []

  constructor(private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this._fetchSubServices()
  }

  async _fetchSubServices() {
    // window.scrollTo(0, 380);
    await this.cmdsService.allSubServices().then((res: any) => {
      if (res) {
        this.allSubServices = res;
      }
    });
  }

  async _visibleSubService(idSubFamily) {
    // window.scrollTo(0, 380);
    await this.cmdsService.visibleSubServices(idSubFamily).then((res: any) => {
      if (res) {
        this.allSubServices = res;
      }
    });
  }

}
