import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-add-sub-service',
  templateUrl: './add-sub-service.component.html',
  styleUrls: ['./add-sub-service.component.css']
})
export class AddSubServiceComponent implements OnInit {

  allFamilies: any = []
  idFamily: string = null

  constructor(private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this._fetchServices()
  }

  async _fetchServices() {
    // window.scrollTo(0, 380);
    await this.cmdsService.allServices().then((res: any) => {
      if (res) {
        this.allFamilies = res;
      }
    });
  }

  async addSubFamily(name) {
    if (name.value != "" && this.idFamily != null && this.idFamily != "null") {
      await this.cmdsService.addSubService(name.value, this.idFamily).then((res: any) => {
        if (res && res.response == true) {
          location.href = '/sub-services'
        }
      });
    } else {
      alert('Les données de la sous famille est invalide');
    }
  }

}
