import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AffectationVehiculeComponent } from 'src/app/components/shared/dialogs/affectation-vehicule/affectation-vehicule.component';
import { UsersService } from 'src/app/services/users-founix/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transportors',
  templateUrl: './transportors.component.html',
  styleUrls: ['./transportors.component.css']
})
export class TransportorsComponent implements OnInit {

  leadersList: Observable<Array<any>>;

  env: any;

  constructor(private trackingServices: UsersService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.env = environment
    this.listLeaders()
  }

  async listLeaders() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
     
    });
  }

  affectVehiculeToTrans(idTrans) {
    const confirmDialog = this.dialog.open(AffectationVehiculeComponent, {
      data: {
        idStore: idTrans,
        // destinationSac: destinationSac,
        vehicule: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        this.trackingServices.affectTransToVehicule(idTrans, data.vehicule).then((res: any) => {
          // console.log("affectStorePrepa : ");
          // console.log(res);
          if (res && !res.response) {
            alert(res.msg)
          }
          this.listLeaders();
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });

      }
    });
  }

}
