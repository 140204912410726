<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-home icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                <div class="page-title-subheading">État actuel du dépôt
                    <div class="badge badge-success">
                        {{store?.store_name}}</div>
                    <div class="page-title-subheading">{{store?.types_colis}}
                    </div>
                </div>
            </div>
        </div>
        <div class="page-title-actions" *ngFor="let prepa of store.prepas, let index = index">
            <!-- La liste des preparateurs :  -->
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <!-- <img width="40" class="rounded-circle"
                                src="assets/images/avatars/2.jpg" alt=""> -->
                            <ngx-avatar class="rounded-circle" name="{{prepa.nom_prenom_tracking}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{prepa.nom_prenom_tracking}}</div>
                        <div class="widget-subheading opacity-7">
                            <b>{{prepa.numTel_tracking}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-xl-3">
        <!-- <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Manifest</div>
                    <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">
                        {{type}}: {{getTypeColis(store?.pendingCmds ,type)}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.pendingCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="col-md-6 col-xl-6">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Au dépôt</div>
                    <!-- <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">
                        {{type}}: {{getTypeColis(store?.inStoreCmds ,type)}}</div> -->
                    <!-- <div class="widget-heading bg-danger">LV: {{getTypeColis(store?.inStoreCmds ,'LV')}}</div>
                    <div class="widget-heading bg-danger">HV: {{getTypeColis(store?.inStoreCmds ,'HV')}}</div> -->
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.receivedCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-6 col-xl-3" (click)="selectStatus(store?.preparedCmds, 'prepared')">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Prêt à la livraison</div>
                    <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">
                        {{type}}: {{getTypeColis(store?.preparedCmds ,type)}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.preparedCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" (click)="getStore()">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-photo-gallery text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Toutes les commandes</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{store?.pendingCmds?.length + store?.preparedCmds?.length +
                            store?.inStoreCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes réceptionnées
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <!-- <button class="active btn btn-info" (click)="filterByType(type)"
                            *ngFor="let type of store.types_colis, let index = index">{{type}}</button> -->
                        <!-- <button class="btn btn-dark" (click)="filterByType('LV')">LV</button>
                        <button class="btn btn-danger" (click)="filterByType('HV')">HV</button> -->
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h2 class="text-center">Pas de commande</h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <!-- <th>Client</th> -->
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date<br>de<br>création</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Total Commande</th>
                            <th class="text-center">
                                Services</th>
                            <th class="text-center">Statut</th>
                            <th class="text-center">Total colis</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">{{cmd.ref_grouping}}</td>
                            <td class="text-center">{{cmd.creation_date_grouping | date:'d MMM y'}}<br>
                                <b>{{cmd.creation_date_grouping | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">{{cmd?.user_location?.main_target}}<br>
                                <strong>{{cmd?.user_location?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{cmd?.user_destination?.main_target}}<br>
                                <strong>{{cmd?.user_destination?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{cmd.price |
                                number:'0.3'}} DT</td>
                            <td class="text-center">
                                <strong>{{cmd?.palette?.name}}</strong><br>
                                <strong>{{cmd?.sub_family?.family?.name}}</strong><br>
                                <strong>{{cmd?.sub_family?.name}}</strong><br>
                                <strong>Quantité: {{cmd?.quantity}}</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.etat_grouping].color}}">
                                    {{statusCmds[cmd.etat_grouping].label}}</div>
                            </td>
                            <td class="text-center">{{cmd?.total_ca_cmd |
                                number:'0.2'}} {{cmd?.currency}}</td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-grouping')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details
                                        </button>
                                        <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-grouping')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>