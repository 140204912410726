<!-- <app-image-viewer [imageURL]="imageURL" [open]="viewerOpen"
                                    (close)="viewerOpen = false"></app-image-viewer> -->

                                    <div class="app-page-title">
                                        <div class="page-title-wrapper">
                                            <div class="page-title-heading">
                                                <div class="page-title-icon">
                                                    <i class="pe-7s-note2 icon-gradient bg-mean-fruit">
                                                    </i>
                                                </div>
                                                <div>Transporteur
                                                    <div class="page-title-subheading">Détail du transporteur.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="main-card mb-3 card">
                                                <div class="tab-content" id="tab-content">
                                                    <div class="main-card mb-12 card">
                                                        <div class="card-body">
                                                            <div id="commande" #commande>
                                                                <div class="row">
                                                                    <div class="col-md-7">
                                                                        <!-- <div class="text space">
                                                                            <h4><strong>Transporteur</strong></h4>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="col-md-5 text-md-right">
                                                                        <div
                                                                            [class]="(transporter.isValidate) ? 'badge badge-success' : 'badge badge-danger'">
                                                                            {{(transporter.isValidate) ? 'Validé' : 'Non validé'}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                    
                                                                <div class="row">
                                                                    <div class="col-md-7">
                                                                        <strong>Transporteur:</strong><br />
                                                                        {{transporter.user_transportor?.prenom_user + " " +
                                                                        transporter?.user_transportor?.nom_user}}<br />
                                                                        {{transporter.user_transportor?.email_user}}<br />
                                                                        {{transporter.user_transportor?.tel_user}}<br /><br />
                                                                        <!-- {{transporter.destination_cmd}}<br /> -->
                                    
                                                                        <strong>Date de l'inscription:</strong><br />
                                                                        {{transporter?.user_transportor?.dateinscrip_user | date:'dd/MM/yyyy'}}<br />
                                                                        <div [class]="(transporter.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                                                                            {{(transporter?.onLine) ? 'En ligne' : 'Hors ligne'}}
                                                                        </div>
                                                                        <!-- <div class="text space">
                                                                            <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                                                                [qrdata]="cmd.qr_code" [errorCorrectionLevel]="'M'">
                                                                            </qrcode>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="col-md-5 text-md-right">
                                                                        <img width="170"
                                                                            src="{{env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user}}"
                                                                            alt=""
                                                                            (click)="viewerOpenImage(env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user)">
                                                                    </div>
                                                                </div>
                                    
                                                                <div class="clearfix"></div>
                                    
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="section-title">Carte d'identité</div>
                                                                                <p class="section-lead">
                                                                                    N° carte d'identé
                                                                                    <br />→ {{transporter?.id_card?.num_id_card}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-6 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto)">
                                                                            </div>
                                                                            <div class="col-6 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                    
                                                                <div class="clearfix"></div>
                                    
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="section-title">Permis de conduire</div>
                                                                                <p class="section-lead">
                                                                                    N° de permis
                                                                                    <br />→ {{transporter?.driving_license?.num_driving_license}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-6 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto)">
                                                                            </div>
                                                                            <div class="col-6 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                    
                                                                <div class="clearfix"></div>
                                    
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="section-title">Infos véhicule</div>
                                                                                <p class="section-lead">
                                                                                    Type de véhicule
                                                                                    <br />→ {{transporter?.info_vehicule?.type_camion}}
                                                                                </p>
                                                                                <p class="section-lead">
                                                                                    Plaque d'immatriculation
                                                                                    <br />→ {{transporter?.info_vehicule?.immatricule.part1}} Tun
                                                                                    {{transporter?.info_vehicule?.immatricule.part2}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-4 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule)">
                                                                            </div>
                                                                            <div class="col-4 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_recto}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_recto)">
                                                                            </div>
                                                                            <div class="col-4 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_verso}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_verso)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                    
                                                                <div class="clearfix"></div>
                                    
                                                                <div class="row" *ngIf="transporter?.patent != null">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="section-title">Carte d'identification fiscale</div>
                                                                                <p class="section-lead">
                                                                                    Numéro de carte d'identification fiscale
                                                                                    <br />→ {{transporter?.patent?.num_patent}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12 text-center">
                                                                                <img width="170"
                                                                                    src="{{env.patentTransportorAssetsBaseURL + transporter?.patent?.patent_card}}"
                                                                                    alt=""
                                                                                    (click)="viewerOpenImage(env.patentTransportorAssetsBaseURL + transporter?.patent?.patent_card)">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                    
                                                        <div class="d-block text-center card-footer">
                                                            <button
                                                                [class]="(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'btn btn-danger float-left' : 'btn btn-success float-left'"
                                                                (click)="blockTransportor()"><i class="metismenu-icon pe-7s-back"></i>
                                                                {{(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'Débloqué le
                                                                transporteur' : 'Bloqué le transporteur'}}
                                                            </button>
                                    
                                                            <button class="btn btn-success float-right text-white" (click)="validateTransportor()"><i
                                                                    class="metismenu-icon pe-7s-check text-white"></i> Valider</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row" *ngIf="transporter?.pendingDemandes != null && transporter?.pendingDemandes?.length > 0">
                                        <div class="col-md-12">
                                            <div class="main-card mb-3 card">
                                                <div class="card-header">Les demandes en cours de {{"'" + transporter.user_transportor?.prenom_user + " " +
                                                    transporter?.user_transportor?.nom_user + "'"}}
                                                    <!-- <div class="btn-actions-pane-right">
                                                        <div role="group" class="btn-group-sm btn-group">
                                                            <button class="active btn btn-focus">Last Week</button>
                                                            <button class="btn btn-focus">All Month</button>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Utilisateurs</th>
                                                                <th class="text-center">Date<br>de la<br>demande</th>
                                                                <th class="text-center">Localisation</th>
                                                                <th class="text-center">Destination</th>
                                                                <th class="text-center">Service</th>
                                                                <th class="text-center">Propositions</th>
                                                                <th class="text-center">Etat</th>
                                                                <th class="text-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngFor="let demande of transporter.pendingDemandes, let index = index">
                                                                <tr>
                                                                    <td class="text-center text-muted">#{{index+1}}</td>
                                                                    <td>
                                                                        <div class="widget-content p-0">
                                                                            <div class="widget-content-wrapper">
                                                                                <div class="widget-content-left mr-3">
                                                                                    <div class="widget-content-left">
                                                                                        <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                                                        ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                                                        ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                                                            alt="">
                                                                                        <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                                                    {{demande.user_transportor.nom_user}}">
                                                                                    </ngx-avatar> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="widget-content-left flex2">
                                                                                    <div class="widget-heading"
                                                                                        (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-transportor')">
                                                                                        {{demande?.user_transportor?.prenom_user}}
                                                                                        {{demande?.user_transportor?.nom_user}}</div>
                                                                                    <div class="widget-subheading opacity-7">
                                                                                        {{demande?.user_transportor?.email_user}}</div>
                                                                                    <div class="widget-subheading">
                                                                                        {{demande?.user_transportor?.tel_user}}</div>
                                                                                    <div
                                                                                        [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                                                        {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                    
                                    
                                                                                    <!-- <div class="widget-subheading">
                                                                                    {{(demande?.isDriver) ? "C'est un chauffeur" : "C'est un client"}}</div> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">{{demande?.action_date_demande | date:'d MMM y HH:mm'}}</td>
                                                                    <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                                                        <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                                                    </td>
                                                                    <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                                                        <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <strong (click)="goToNewTab(demande?.type_vehicule, '/parc-transportors')">{{demande?.type_vehicule}}</strong><br>
                                                                        <strong>{{demande?.type_service}}</strong><br>
                                                                        <strong>{{demande?.type_products}}</strong><br>
                                                                        <strong *ngIf="demande?.price != null" style="color: tomato;">{{demande?.price}} DT</strong>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                                                                <li class="list-group-item"
                                                                                    *ngFor="let driver of demande.driver_responses, let index = index">
                                                                                    <div class="widget-content p-0">
                                                                                        <div class="widget-content-wrapper">
                                                                                            <div class="widget-content-left mr-3">
                                                                                                <img width="40" height="40" class="rounded-circle"
                                                                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + driver?.driver_id?.user_transportor?.profile_user}}"
                                                                                                    alt="">
                                                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                                                    </ngx-avatar> -->
                                                                                            </div>
                                                                                            <div class="widget-content-left">
                                                                                                <div class="widget-heading"
                                                                                                    (click)="goToNewTab(driver?.driver_id?._id, '/detail-transportor')">
                                                                                                    {{driver?.driver_id?.user_transportor?.prenom_user}}
                                                                                                    {{driver?.driver_id?.user_transportor?.nom_user}}
                                                                                                </div>
                                                                                                <div class="widget-subheading">
                                                                                                    {{driver?.driver_id?.user_transportor?.tel_user}}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="widget-content-right">
                                                                                                <div class="font-size-xlg text-muted">
                                                                                                    <span>{{driver?.proposition}} DT</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <!-- <td class="text-center">
                                                                    <b>{{element.client?.datePickUp}}</b>
                                                                </td> -->
                                                                    <td class="text-center">
                                                                        <div class="badge badge-{{statusDemandes[demande?.etat_demande]?.color}}">
                                                                            {{statusDemandes[demande?.etat_demande]?.label}}</div>
                                                                        <div *ngIf="demande?.etat_demande == 'canceled'">
                                                                            <b>{{demande?.msg_returned_cmd}}</b><br>
                                                                            <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                                                            <button class="btn-wide btn btn-info">Actions</button>
                                                                            <button type="button" ngbDropdownToggle
                                                                                class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                                                    class="sr-only">Actions</span></button>
                                                                            <div ngbDropdownMenu>
                                                                                <button type="button" [routerLink]="['/details-demande']"
                                                                                    [queryParams]="{ demande: demande._id }" tabindex="0"
                                                                                    class="dropdown-item">
                                                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                                                        style="margin-right: 10px;"></i>Details demande
                                                                                </button>
                                                                                <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                                                                [routerLink]="['/frais-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Ajouter frais</button>
                                                                            <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                                                                [routerLink]="['/edit-frais-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Modifier frais</button>
                                                                            <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Compte client</button>
                                                                            <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                                                                class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button [routerLink]="['/detail-cmds-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1"
                                                                        class="btn btn-primary btn-sm">Details</button> -->
                                    
                                                                        <!-- <button *ngIf="element.fraisClient == null"
                                                                        [routerLink]="['/frais-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                                                        <!-- <button *ngIf="element.fraisClient != null"
                                                                        [routerLink]="['/edit-frais-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->
                                    
                                                                        <!-- <button [routerLink]="['/compte-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->
                                    
                                                                        <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                                                        id="PopoverCustomT-1"
                                                                        class="btn btn-warning btn-sm">Affecter</button> -->
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="demande.acceptedBy != null" style="background-color: rgb(146, 238, 207);">
                                                                    <td colspan="3" class="text-center">
                                                                        <b>Transport accepté le {{demande?.acceptingDate | date:'d MMM y HH:mm'}}, par : </b>
                                                                    </td>
                                                                    <td colspan="4">
                                                                        <div class="widget-content p-0">
                                                                            <div class="widget-content-wrapper">
                                                                                <div class="widget-content-left mr-3">
                                                                                    <img width="40" height="40" class="rounded-circle"
                                                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                                                        alt="">
                                                                                </div>
                                                                                <div class="widget-content-left mr-5">
                                                                                    <div class="widget-heading"
                                                                                        (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}
                                                                                    </div>
                                                                                    <div class="widget-subheading">
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="widget-content-left">
                                                                                    <!-- <div class="font-size-xlg text-muted"> -->
                                                                                        <div class="badge badge-success">
                                                                                            Acceptée</div>
                                                                                        <!-- <span>{{demande?.acceptedBy?.proposition}} DT</span> -->
                                                                                    <!-- </div> -->
                                                                                </div>
                                                                                <div class="widget-content-right">
                                                                                    <div class="font-size-xlg text-muted">
                                                                                        <span  style="color: rgb(71, 105, 255);">{{demande?.acceptedBy?.proposition}} DT</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <div *ngIf="demande.bonusPromo!=null" class="font-size-xlg text-muted text-right">
                                                                            <span  style="color: tomato;">Bonus: -{{demande?.bonusPromo}} DT</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                                                    <td colspan="9" class="text-center"><b>Transport planifié le {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row" *ngIf="transporter?.canceledDemandes != null && transporter?.canceledDemandes?.length > 0">
                                        <div class="col-md-12">
                                            <div class="main-card mb-3 card">
                                                <div class="card-header">Les demandes annulé de {{"'" + transporter.user_transportor?.prenom_user + " " +
                                                    transporter?.user_transportor?.nom_user + "'"}}
                                                    <!-- <div class="btn-actions-pane-right">
                                                        <div role="group" class="btn-group-sm btn-group">
                                                            <button class="active btn btn-focus">Last Week</button>
                                                            <button class="btn btn-focus">All Month</button>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">#</th>
                                                                <th>Utilisateurs</th>
                                                                <th class="text-center">Date<br>de la<br>demande</th>
                                                                <th class="text-center">Localisation</th>
                                                                <th class="text-center">Destination</th>
                                                                <th class="text-center">Service</th>
                                                                <th class="text-center">Propositions</th>
                                                                <th class="text-center">Etat</th>
                                                                <th class="text-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container *ngFor="let demande of transporter.canceledDemandes, let index = index">
                                                                <tr>
                                                                    <td class="text-center text-muted">#{{index+1}}</td>
                                                                    <td>
                                                                        <div class="widget-content p-0">
                                                                            <div class="widget-content-wrapper">
                                                                                <div class="widget-content-left mr-3">
                                                                                    <div class="widget-content-left">
                                                                                        <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                                                        ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                                                        ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                                                            alt="">
                                                                                        <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                                                    {{demande.user_transportor.nom_user}}">
                                                                                    </ngx-avatar> -->
                                                                                    </div>
                                                                                </div>
                                                                                <div class="widget-content-left flex2">
                                                                                    <div class="widget-heading"
                                                                                        (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-transportor')">
                                                                                        {{demande?.user_transportor?.prenom_user}}
                                                                                        {{demande?.user_transportor?.nom_user}}</div>
                                                                                    <div class="widget-subheading opacity-7">
                                                                                        {{demande?.user_transportor?.email_user}}</div>
                                                                                    <div class="widget-subheading">
                                                                                        {{demande?.user_transportor?.tel_user}}</div>
                                                                                    <div
                                                                                        [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                                                        {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                    
                                    
                                                                                    <!-- <div class="widget-subheading">
                                                                                    {{(demande?.isDriver) ? "C'est un chauffeur" : "C'est un client"}}</div> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">{{demande?.action_date_demande | date:'d MMM y HH:mm'}}</td>
                                                                    <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                                                        <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                                                    </td>
                                                                    <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                                                        <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <strong (click)="goToNewTab(demande?.type_vehicule, '/parc-transportors')">{{demande?.type_vehicule}}</strong><br>
                                                                        <strong>{{demande?.type_service}}</strong><br>
                                                                        <strong>{{demande?.type_products}}</strong><br>
                                                                        <strong *ngIf="demande?.price != null" style="color: tomato;">{{demande?.price}} DT</strong>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                                                                <li class="list-group-item"
                                                                                    *ngFor="let driver of demande.driver_responses, let index = index">
                                                                                    <div class="widget-content p-0">
                                                                                        <div class="widget-content-wrapper">
                                                                                            <div class="widget-content-left mr-3">
                                                                                                <img width="40" height="40" class="rounded-circle"
                                                                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + driver?.driver_id?.user_transportor?.profile_user}}"
                                                                                                    alt="">
                                                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                                                    </ngx-avatar> -->
                                                                                            </div>
                                                                                            <div class="widget-content-left">
                                                                                                <div class="widget-heading"
                                                                                                    (click)="goToNewTab(driver?.driver_id?._id, '/detail-transportor')">
                                                                                                    {{driver?.driver_id?.user_transportor?.prenom_user}}
                                                                                                    {{driver?.driver_id?.user_transportor?.nom_user}}
                                                                                                </div>
                                                                                                <div class="widget-subheading">
                                                                                                    {{driver?.driver_id?.user_transportor?.tel_user}}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="widget-content-right">
                                                                                                <div class="font-size-xlg text-muted">
                                                                                                    <span>{{driver?.proposition}} DT</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <!-- <td class="text-center">
                                                                    <b>{{element.client?.datePickUp}}</b>
                                                                </td> -->
                                                                    <td class="text-center">
                                                                        <div class="badge badge-{{statusDemandes[demande?.etat_demande]?.color}}">
                                                                            {{statusDemandes[demande?.etat_demande]?.label}}</div>
                                                                        <div *ngIf="demande?.etat_demande == 'canceled'">
                                                                            <b>{{demande?.msg_returned_cmd}}</b><br>
                                                                            <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                                                            <button class="btn-wide btn btn-info">Actions</button>
                                                                            <button type="button" ngbDropdownToggle
                                                                                class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                                                    class="sr-only">Actions</span></button>
                                                                            <div ngbDropdownMenu>
                                                                                <button type="button" [routerLink]="['/details-demande']"
                                                                                    [queryParams]="{ demande: demande._id }" tabindex="0"
                                                                                    class="dropdown-item">
                                                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                                                        style="margin-right: 10px;"></i>Details demande
                                                                                </button>
                                                                                <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                                                                [routerLink]="['/frais-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Ajouter frais</button>
                                                                            <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                                                                [routerLink]="['/edit-frais-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Modifier frais</button>
                                                                            <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                                                                [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                                                    class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Compte client</button>
                                                                            <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                                                                class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                                                    style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                                                            </div>
                                                                        </div>
                                                                        <!-- <button [routerLink]="['/detail-cmds-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1"
                                                                        class="btn btn-primary btn-sm">Details</button> -->
                                    
                                                                        <!-- <button *ngIf="element.fraisClient == null"
                                                                        [routerLink]="['/frais-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                                                        <!-- <button *ngIf="element.fraisClient != null"
                                                                        [routerLink]="['/edit-frais-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->
                                    
                                                                        <!-- <button [routerLink]="['/compte-client']"
                                                                        [queryParams]="{ client: element.client._id }" type="button"
                                                                        id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->
                                    
                                                                        <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                                                        id="PopoverCustomT-1"
                                                                        class="btn btn-warning btn-sm">Affecter</button> -->
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="demande.acceptedBy != null" style="background-color: rgb(146, 238, 207);">
                                                                    <td colspan="3" class="text-center">
                                                                        <b>Transport accepté le {{demande?.acceptingDate | date:'d MMM y HH:mm'}}, par : </b>
                                                                    </td>
                                                                    <td colspan="4">
                                                                        <div class="widget-content p-0">
                                                                            <div class="widget-content-wrapper">
                                                                                <div class="widget-content-left mr-3">
                                                                                    <img width="40" height="40" class="rounded-circle"
                                                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                                                        alt="">
                                                                                </div>
                                                                                <div class="widget-content-left mr-5">
                                                                                    <div class="widget-heading"
                                                                                        (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}
                                                                                    </div>
                                                                                    <div class="widget-subheading">
                                                                                        {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="widget-content-left">
                                                                                    <!-- <div class="font-size-xlg text-muted"> -->
                                                                                        <div class="badge badge-success">
                                                                                            Acceptée</div>
                                                                                        <!-- <span>{{demande?.acceptedBy?.proposition}} DT</span> -->
                                                                                    <!-- </div> -->
                                                                                </div>
                                                                                <div class="widget-content-right">
                                                                                    <div class="font-size-xlg text-muted">
                                                                                        <span  style="color: rgb(71, 105, 255);">{{demande?.acceptedBy?.proposition}} DT</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <div *ngIf="demande.bonusPromo!=null" class="font-size-xlg text-muted text-right">
                                                                            <span  style="color: tomato;">Bonus: -{{demande?.bonusPromo}} DT</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                                                    <td colspan="9" class="text-center"><b>Transport planifié le {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>