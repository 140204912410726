import { Component, OnInit, ViewChild } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-pending-grouping',
  templateUrl: './pending-grouping.component.html',
  styleUrls: ['./pending-grouping.component.css']
})
export class PendingGroupingComponent implements OnInit {

  @ViewChild('element') element;
  public position = { X: 'Right', Y: 'Bottom' };

  statusCmds: any = [];
  allDemandes: any = [];
  nbrDemande: number = 0

  subscription: Subscription;
  intervalId: number = null;

  constructor(private transServices: GoupingService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusGroupage().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listDemandes()

    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      this.listDemandes()
    });
  }

  goToNewTab(idCmd, route) {
    this.transServices.goToNewTab(idCmd, route)
  }

  //////////////// notif
  playSound() {
    var audio = new Audio();
      // audio.src = "http://www.schillmania.com/projects/soundmanager2/demo/mpc/audio/CHINA_1.mp3";
      audio.src = "https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7";
      audio.load();
      audio.play();
  }

  async listDemandes() {
    await this.transServices.getPendingDemandes().then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allDemandes = res;

        if(this.allDemandes.length != 0 && this.allDemandes.length!= this.nbrDemande) {
          // console.log("sound")
          this.playSound();
        }
        this.nbrDemande = this.allDemandes.length
      }
    });
  }

}
