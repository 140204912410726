import { Component, OnInit } from '@angular/core';
import { TeamsService } from 'src/app/services/teams-founix/teams.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teams-phoenix',
  templateUrl: './teams-phoenix.component.html',
  styleUrls: ['./teams-phoenix.component.css']
})
export class TeamsPhoenixComponent implements OnInit {

  allTeams: any = []
  
  env: any;

  constructor(private teamsService: TeamsService) { }

  ngOnInit(): void {
    this.env = environment
    this.listTeams();
  }

  async listTeams() {
    await this.teamsService.allTeams().then((res: any) => {
      // console.log("allTeams")
      // console.log(res)
      if (res) {
        this.allTeams = res;
      }
    });
  }

}
