import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  saveToken(fcmtoken) {
    var token = localStorage.getItem('token-founix')
    return new Promise((solve) => {
      var decoded = jwt_decode(token);

      this.http.post(environment.apiSaveTokenPH, {
        fcmtoken: fcmtoken,
        userid: decoded["id"]
      }).subscribe((res: any) => {
        // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        // console.log(res);
        if (res) {
            solve(res.response);
        }
      })
    });
  }

  // getUserInfos() {
  //   var token = localStorage.getItem('token-founix')

  //   //console.log("tokennnnnnnnn : ");
  //   //console.log(token);

  //   return new Promise((solve) => {
  //     if (token && token != null) {
  //       var decoded = jwt_decode(token);

  //       this.http.post(environment.apiInfosUser, { token: token, teamid: decoded["id"] }).subscribe((res: any) => {
  //         // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
  //         // console.log(res);
  //         if (res && res.response && res.data) {
  //           solve(res.data);
  //         } else
  //           solve(true);
  //       },
  //         (error) => {
  //           // console.log("Cmd error")
  //           // console.log(error)
  //           solve(false);
  //         })
  //     } else {
  //       solve(false);
  //     }
  //   });

  //   // let user = localStorage.getItem('userdetails');
  //   // if (user && JSON.parse(user))
  //   //   return JSON.parse(user);

  //   // return null;
  // }

  isConnected() {
    var token = localStorage.getItem('token-founix')
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  saveUserCreds(usercreds) {
    localStorage.setItem('token-founix', JSON.stringify(usercreds.token));
    localStorage.setItem('currentUser', JSON.stringify(usercreds));
  }

  login(creds) {
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            this.currentUserSubject.next(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }

  allTeams() {
    return new Promise((solve) => {
      this.http.post(environment.apiAllTeams, {}).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            solve(res.data);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  addUserPH(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiAddUserPH, creds).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response) {
            solve(res.response);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token-founix');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
