import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-facture-grouping',
  templateUrl: './facture-grouping.component.html',
  styleUrls: ['./facture-grouping.component.css']
})
export class FactureGroupingComponent implements OnInit {

  groupID: string = ""
  grouping: any = {}

  constructor(private route: ActivatedRoute,
    private transServices: GoupingService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.groupID = params.cmd;
      this._fetchGrouping();
    });
  }

  _fetchGrouping() {
    this.transServices.getOneGrouping(this.groupID).then((data: any) => {
      // console.log("_fetchGrouping");
      // console.log(data);
      if (data) {
        this.grouping = data;
      } else {
        alert('Aucune demande valide');
        location.href = '/';
      }
    });
  }

}
