<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demandes de transport
                <div class="page-title-subheading">La liste des demandes de transport.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterDemandes(name, numero, datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="name" class="">Transporteur</legend>
                                        <input name="name" id="name" type="text" class="form-control" #name ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="number" class="form-control" #numero
                                            ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Status</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric" (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            #datefin ngModel>
                                    </div>
                                </div>
                                <!-- <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="isPlanified" class="">Planifié</legend>
                                        <input id="isPlanified" type="checkbox" class="form-control" name="isPlanified" tabindex="1"
                                            #isPlanified ngModel>
                                    </div>
                                </div> -->
                                <button type="submit" (click)="filterDemandes(name, numero, datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Service</legend>
                                        <select class="form-control selectric select-size"
                                            (change)="selectService($event)">
                                            <option value="null">
                                                Tous les services</option>
                                            <option [value]="ser.service" *ngFor="let ser of serviceList">
                                                {{ser.service}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Véhicule</legend>
                                        <select class="form-control selectric select-size"
                                            (change)="selectCamion($event)">
                                            <option value="null">
                                                Tous les véhicules</option>
                                            <option [value]="veh.name" *ngFor="let veh of vehList">
                                                {{veh.name}}
                                                <img src="{{veh.image}}">
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Planifié</legend>
                                        <select id="planified" name="planified"
                                            class="form-control selectric select-size" [(ngModel)]="planified">
                                            <option value="all">Tous</option>
                                            <option value="true">OUI</option>
                                            <option value="false">NON</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2" *ngIf="planified == 'true'">
                                    <div class="position-relative form-group">
                                        <legend for="dateinPlan" class="">Date Début (Planification)</legend>
                                        <input id="dateinPlan" type="date" class="form-control" name="dateinPlan"
                                            tabindex="1" [(ngModel)]="dateinPlan">
                                    </div>
                                </div>
                                <div class="col-md-2" *ngIf="planified == 'true'">
                                    <div class="position-relative form-group">
                                        <legend for="datefinPlan" class="">Date Fin (Planification)</legend>
                                        <input id="datefinPlan" type="date" class="form-control" name="datefinPlan"
                                            tabindex="1" [(ngModel)]="datefinPlan">
                                    </div>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Demandes
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de la<br>demande</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Service</th>
                            <th class="text-center">Propositions</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let demande of allDemandes, let index = index">
                            <tr>
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                {{demande.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-transportor')">
                                                    {{demande?.user_transportor?.prenom_user}}
                                                    {{demande?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.user_transportor?.email_user}}</div>
                                                <div class="widget-subheading">
                                                    {{demande?.user_transportor?.tel_user}}</div>
                                                <div
                                                    [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                    {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                                <div class="widget-subheading"
                                                    *ngIf="demande?.flux != null && demande?.flux != ''">
                                                    <b>→ {{demande?.flux}}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{demande?.action_date_demande | date:'d MMM y HH:mm'}}</td>
                                <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                    <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                    <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">
                                    <strong
                                        (click)="goToNewTab(demande?.type_vehicule, '/parc-transportors')">{{demande?.type_vehicule}}</strong><br>
                                    <strong>{{demande?.type_service}}</strong><br>
                                    <strong>{{demande?.type_products}}</strong><br>
                                    <strong *ngIf="demande?.price != null" style="color: tomato;">{{demande?.price}}
                                        DT</strong>
                                </td>
                                <td>
                                    <div>
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                            <li class="list-group-item"
                                                *ngFor="let driver of demande.driver_responses, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40" class="rounded-circle"
                                                                src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + driver?.driver_id?.user_transportor?.profile_user}}"
                                                                alt="">
                                                            <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading"
                                                                (click)="goToNewTab(driver?.driver_id?._id, '/detail-transportor')">
                                                                {{driver?.driver_id?.user_transportor?.prenom_user}}
                                                                {{driver?.driver_id?.user_transportor?.nom_user}}
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{driver?.driver_id?.user_transportor?.tel_user}}
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-xlg text-muted">
                                                                <span>{{driver?.proposition}} DT</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                                <td class="text-center">
                                    <div class="badge badge-{{statusDemandes[demande?.etat_demande]?.color}}">
                                        {{statusDemandes[demande?.etat_demande]?.label}}</div>
                                    <div *ngIf="demande?.etat_demande == 'canceled'">
                                        <b>{{demande?.msg_returned_cmd}}</b><br>
                                        <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <!-- <button type="submit" (click)="addAppel(demande)" style="margin-bottom: 5px;"
                                        class="btn btn-lg btn-icon icon-righ btn-search" id="phone" tabindex="4"
                                        [class]="(demande.appel_by != null) ? 'btn-success' : 'btn-danger'">
                                        <i class="fa fa-phone"></i>
                                    </button>
                                    <button type="submit" (click)="addCommentsDemande(demande)"
                                        style="margin-bottom: 5px;"
                                        class="btn btn-warning btn-lg btn-icon icon-righ btn-search" id="search"
                                        tabindex="4">
                                        {{((demande?.comments != null) ? demande?.comments.length : 0) + " "}}<i
                                            class="pe-7s-paper-plane"></i>
                                    </button> -->
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button"
                                                (click)="goToNewTabDemande(demande._id, '/details-demande')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details demande
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="demande.acceptedBy != null" style="background-color: rgb(146, 238, 207);">
                                <td colspan="3" class="text-center">
                                    <b>Transport accepté le {{demande?.acceptingDate | date:'d MMM y HH:mm'}}, par :
                                    </b>
                                </td>
                                <td colspan="4">
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                    alt="">
                                            </div>
                                            <div class="widget-content-left mr-5">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}
                                                </div>
                                                <div class="widget-subheading">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}
                                                </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <!-- <div class="font-size-xlg text-muted"> -->
                                                <div class="badge badge-success">
                                                    Acceptée</div>
                                                <!-- <span>{{demande?.acceptedBy?.proposition}} DT</span> -->
                                                <!-- </div> -->
                                            </div>
                                            <div class="widget-content-right">
                                                <div class="font-size-xlg text-muted">
                                                    <span
                                                        style="color: rgb(71, 105, 255);">{{demande?.acceptedBy?.proposition}}
                                                        DT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div *ngIf="demande.bonusPromo!=null" class="font-size-xlg text-muted text-right">
                                        <span style="color: tomato;">Bonus: -{{demande?.bonusPromo}} DT</span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                <td colspan="9" class="text-center"><b>Transport planifié le
                                        {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="d-block text-center card-footer" *ngIf="allDemandes?.length > 0 && pages?.length > 1">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="previousPage(name, numero, datein, datefin)">
                                <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                Précédent</a>
                        </li>

                        <div *ngFor="let p of pagesDisplaied">
                            <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                <a *ngIf="currentPage!==p" class="page-link"
                                    (click)="selectPage(p, name, numero, datein, datefin)">{{p+1}}</a>

                                <span *ngIf="currentPage===p" class="page-link">
                                    {{p+1}}
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                        </div>
                        <!-- <li class="page-item active">
                                            <span class="page-link">
                                                2
                                                <span class="sr-only">(current)</span>
                                            </span>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                        <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="nextPage(name, numero, datein, datefin)">Suivant
                                <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                        </li>
                    </ul>
                </nav>

                <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                <button class="btn-wide btn btn-success">Save</button> -->
            </div>
        </div>
    </div>
</div>