import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  allSocties:any = []

  constructor(private transServices: GoupingService) { }

  ngOnInit(): void {
    this.allSocieties()
  }

  async allSocieties() {
    await this.transServices.allSocieties().then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.allSocties = res;
      }
    });
  }

  filterCommandes(grouping, status) {
    var array = grouping.filter(cmd => status.includes(cmd.etat_grouping));
    return array.length
  }
}
