<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commande
                <div class="page-title-subheading">Détails de la commande.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <h4><strong>Commande</strong></h4>
                                    </div>
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <h4><strong>#{{cmd.ref_grouping}}</strong></h4>
                                    <!-- <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                            [errorCorrectionLevel]="'M'"></qrcode> -->
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                            [qrdata]="cmd._id" [errorCorrectionLevel]="'M'">
                                        </qrcode>
                                    </div>
                                    <div class="marg-top" *ngIf="cmd.isPlanified == true">
                                        <strong>Planifié le:</strong><br />
                                        {{cmd?.planificationDate | date:'d MMM y HH:mm'}}<br /><br /><br />
                                    </div>
                                    <div [class]="(cmd.isPlanified == true) ? '' : 'marg-top'">
                                        Prélevé le payement: <strong>{{(cmd?.with_payment) ? 'OUI' :
                                            'NON'}}</strong><br /><br />
                                    </div>
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <strong>Client:</strong><br />
                                    {{cmd.user_transportor?.prenom_user + " " + cmd.user_transportor?.nom_user}}<br />
                                    {{cmd.user_transportor?.email_user}}<br />
                                    {{cmd.user_transportor?.tel_user}}<br /><br />

                                    <strong>Localisation:</strong><br />
                                    {{cmd.user_location?.label_target}}<br />
                                    {{cmd.user_location?.main_target}}<br />
                                    {{cmd.user_location?.sencondary_target}}<br /><br />

                                    <strong>Destination:</strong><br />
                                    {{cmd.user_destination?.label_target}}<br />
                                    {{cmd.user_destination?.main_target}}<br />
                                    {{cmd.user_destination?.sencondary_target}}<br /><br />

                                    <strong>Date de commande:</strong><br />
                                    {{cmd?.creation_date_grouping | date:'dd/MM/yyyy'}}<br /><br />
                                    <div class="badge badge-{{statusCmds[cmd.etat_grouping]?.color}}">
                                        {{statusCmds[cmd.etat_grouping]?.label}}
                                    </div><br />
                                    <h2 *ngIf="cmd.etat_grouping=='receive'">{{cmd?.store?.store?.store_name}}</h2>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <div class="main-card mb-3 card"> -->
                                    <div class="section-title">
                                        Détails de la commande
                                    </div>

                                    <div class="table-responsive">
                                        <!-- <div class="budget-price justify-content-center"
                                            *ngIf="cmd.products?.length === 0">
                                            <h5 class="text-center">Aucune produit affecté dans la
                                                commande
                                            </h5>
                                        </div> -->
                                        <table
                                            class="align-middle mb-0 table table-borderless table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Palette</th>
                                                    <th class="text-center">Quantité</th>
                                                    <th class="text-center">Prix unitaire</th>
                                                    <th class="text-center">Montant Total HT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>#</td>
                                                    <td>
                                                        {{cmd?.palette?.name}}<br>
                                                        <!-- <b *ngIf="cmd.isExchange==true">Echange ({{prd.quantite}} article(s))</b> -->
                                                    </td>
                                                    <td class="text-center">{{cmd?.quantity}}</td>
                                                    <td class="text-center">{{cmd?.palette?.price*((100-19)/100) |
                                                        number:'0.3'}} TND</td>
                                                    <td class="text-center">
                                                        <b>{{(cmd?.palette?.price*((100-19)/100))*cmd.quantity
                                                            |
                                                            number:'0.3'}} TND</b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row mt-4">
                                <div class="col-lg-8">
                                    <div class="section-title">Mode de livraison</div>
                                    <p class="section-lead">
                                        À domicile
                                        <br />→ {{cmd?.user_destination?.main_target}}
                                    </p>
                                </div>
                                <div class="col-lg-4 text-right">
                                    <div class="invoice-detail-item">
                                        <div class="invoice-detail-name">Montant HT:
                                            {{((cmd?.price*(100-19))/100) |
                                            number:'0.3'}} TND</div>
                                        <div class="invoice-detail-name">TVA 19%:
                                            {{((cmd?.price*19/100)) |
                                            number:'0.3'}} TND
                                        </div>
                                        <div class="invoice-detail-value">Montant TTC:
                                            {{cmd?.price |
                                            number:'0.3'}} TND
                                        </div>
                                        <!-- <div class="invoice-detail-value">Poids: {{cmd.weight_cmd}} g</div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-block text-center card-footer">
                        <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button> -->

                        <!-- <button class="btn-wide btn btn-success">Save</button> -->
                        <button class="btn btn-danger float-left" [routerLink]="[ '/all-grouping']"><i
                                class="metismenu-icon pe-7s-back"></i> Retour</button>

                        <button class="btn btn-warning float-right text-white" [routerLink]="['/facture-grouping']"
                            [queryParams]="{ cmd: cmd._id }"><i class="metismenu-icon pe-7s-print text-white"></i>
                            Imprimer</button>
                        <!-- <button class="btn btn-warning float-right" (click)="generatePDF()"><i
                                class="metismenu-icon pe-7s-print"></i> Imprimer</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="cmd?.photo_grouping?.length > 0">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Multiple Upload</div>
            <div class="card-body">
                <div class="chat-container">
                    <div class="Neon Neon-theme-dragdropbox">

                        <div class="Neon-input-dragDrop">
                            <div class="Neon-input-inner">
                                <div class="Neon-input-icon" *ngIf="cmd?.photo_grouping?.length==0">
                                    <i class="fa fa-file-image-o"></i>
                                </div>
            
                                <!-- <div class="col-2">
                                    <div class="avatar-item mb-0">
                                      <img alt="image" src="../assets/img/avatar/avatar-5.png" class="img-fluid" data-toggle="tooltip" title="Alfa Zulkarnain">
                                      <div class="avatar-badge" title="Editor" data-toggle="tooltip"><i class="fas fa-wrench"></i></div>
                                    </div>
                                  </div> -->
            
                                <div class="col-lg-12  Neon-input-icon" *ngIf="cmd?.photo_grouping?.length>0">
                                    <div class="col-2" *ngFor='let url of cmd?.photo_grouping; let index = index'>
                                        <img src="https://assets.marketbey.tn/Founix/clients/bon_livraison/{{url}}" height="120"
                                            width="120px">
                                        <!-- <div class="avatar-badge" title="Editor"
                                            (click)="removeSelectedFile(index)"
                                            data-toggle="tooltip"><i
                                                class="fas fa-times icon"></i>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <div class="Neon-input-text">
                                    <h3>Drag&amp;Drop files here</h3> <span
                                        style="display:inline-block; margin: 5px 0">or</span>
                                </div> -->
                                <div>
                                    <!-- <input
                                        style="z-index: 999; opacity: 0; height: 60px; position: absolute; right: 0px; 
                                            left: 0px; margin-right: auto; margin-left: auto;"
                                        name="file" id="file" #file accept="image/*"
                                        type="file" multiple
                                        (change)="onFileChange($event)"> -->
                                    <!-- <a class="Neon-input-choose-btn blue">Browse
                                        Files</a> -->
                                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="cmd?.comments?.length > 0">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commentaires</div>
            <div class="card-body">
                <div class="chat-container">
                    <ul class="chat-box chatContainerScroll">
                        <div *ngFor="let comment of cmd.comments, let index = index">

                            <li class="chat-right" *ngIf="comment.type_user == 'client'">
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-avatar">
                                    <!-- <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                        alt="Retail Admin"> -->
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                            </li>

                            <li class="chat-left" *ngIf="comment.type_user !== 'client'">
                                <div class="chat-avatar">
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                            </li>

                        </div>

                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-8">
        <div class="main-card mb-3 card">
            <div class="card-header">Détail de la commande "<strong>{{cmd.ref_grouping}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Details <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Statut</th>
                                <th>Description</th>
                                <th>Date de l'action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <!-- <td><a href="#"  class="font-weight-600">INV-87239</a></td> -->
                                <td>
                                    <div class="badge badge-warning">Créé</div>
                                </td>
                                <td class="font-weight-600">Par
                                    {{cmd?.user_transportor?.prenom_user + " " + cmd?.user_transportor?.nom_user}}
                                </td>
                                <td>{{cmd.creation_date_grouping | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <tr *ngIf="cmd.pcollect_transport!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['pick_uped']?.color}}">
                                        {{statusCmds['pick_uped'].label}}</div>
                                </td>
                                <td class="font-weight-600">Collecté par
                                    {{cmd.pcollect_transport?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd.pcollect_transport?.date_accept | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <!-- <tr *ngIf="cmd.to_governorate!=null">
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting_to']?.color}}">
                                        {{statusCmds['transporting_to'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis en cours de transport vers
                                    "{{cmd.destination_cmd}}"
                                </td>
                                <td>{{cmd.to_governorate | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr> -->
                            <!-- <tr *ngIf="cmd.between_governorate!=null">
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting_between']?.color}}">
                                        {{statusCmds['transporting_between'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis en cours de transport entre
                                    "{{cmd.destination_cmd}}"
                                </td>
                                <td>{{cmd.to_governorate | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr> -->
                            <tr *ngIf="cmd.transportingDate!=null">
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting']?.color}}">
                                        {{statusCmds['transporting'].label}}</div>
                                </td>
                                <td class="font-weight-600">Commande en cours de transport dans
                                    "{{cmd?.user_destination?.main_target}}"<br>
                                    Par
                                    {{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd?.transportingDate | date:'MMM d, y h:mm:ss
                                    a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.deliveredDate!=null">
                                <!-- <td><a href="#">INV-48574</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['delivered']?.color}}">
                                        {{statusCmds['delivered'].label}}</div>
                                </td>
                                <td class="font-weight-600">Commande livrée au client
                                    "{{cmd?.user_transportor?.prenom_user + " " + cmd?.user_transportor?.nom_user}}"
                                </td>
                                <td>{{cmd.deliveredDate | date:'MMM d, y h:mm:ss
                                    a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.payed_cmd==true">
                                <!-- <td><a href="#">INV-84990</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['paied']?.color}}">
                                        {{statusCmds['paied'].label}}
                                    </div>
                                </td>
                                <td class="font-weight-600">Commande payé à l'expéditeur<br>
                                    "{{cmd.client_cmd.prenom_client}} {{cmd.client_cmd.nom_client}}"</td>
                                <td>{{cmd.date_payed_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <tr *ngIf="cmd.date_returned_cmd!=null">
                                <!-- <td><a href="#">INV-87320</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['returned']?.color}}">
                                        {{statusCmds['returned'].label}}
                                    </div>
                                </td>
                                <td class="font-weight-600"
                                    *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id==null">
                                    Par
                                    {{cmd?.transport_affect[cmd?.transport_affect?.length-2]?.transporter_id?.nom_prenom_tracking}}<br />{{cmd.msg_returned_cmd}}
                                </td>
                                <td class="font-weight-600"
                                    *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id!=null">
                                    Par
                                    {{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id?.nom_prenom_tracking}}<br />{{cmd.msg_returned_cmd}}
                                </td>
                                <td>{{cmd.date_returned_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <!-- <tr>
                                <td class="text-center text-muted">#345</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/4.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">John Doe</div>
                                                <div class="widget-subheading opacity-7">Web Developer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Madrid</td>
                                <td class="text-center">
                                    <div class="badge badge-warning">Pending</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-1"
                                        class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#347</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/3.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Ruben Tillman</div>
                                                <div class="widget-subheading opacity-7">Etiam sit amet orci
                                                    eget</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Berlin</td>
                                <td class="text-center">
                                    <div class="badge badge-success">Completed</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-2"
                                        class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#321</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/2.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Elliot Huber</div>
                                                <div class="widget-subheading opacity-7">Lorem ipsum dolor
                                                    sic</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">London</td>
                                <td class="text-center">
                                    <div class="badge badge-danger">In Progress</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-3"
                                        class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#55</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/1.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Vinnie Wagstaff</div>
                                                <div class="widget-subheading opacity-7">UI Designer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Amsterdam</td>
                                <td class="text-center">
                                    <div class="badge badge-info">On Hold</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-4"
                                        class="btn btn-primary btn-sm">Details</button>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row" *ngIf="cmd?.appel_client?.length>0">
    <div class="col-md-8">
        <div class="main-card mb-3 card">
            <div class="card-header">Détail des appels de la commande
                "<strong>{{cmd.ref_facture_cmd}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1"
                            class="btn btn-danger btn-sm">{{cmd.appel_client.length}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Indice</th>
                                <th>Transporteur</th>
                                <th>Date de l'appel</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let appel of cmd.appel_client, let index = index">
                                <td>
                                    {{index+1}}
                                </td>
                                <td class="font-weight-600">Par
                                    {{appel.transporter_id?.nom_prenom_tracking}}</td>
                                <td>{{appel.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>