<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Véhicule
                <div class="page-title-subheading">Ajouter une nouvelle véhicule.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addVehicule(vehiculeName);">
                <div class="position-relative row form-group">
                    <div class="col-sm-12">
                        <legend for="vehiculeName" class="space-bottom">Nom de la véhicule
                        </legend>
                        <input name="vehiculeName" id="vehiculeName" type="text" class="form-control" #vehiculeName ngModel>
                        <mat-error style="color: red;font-weight: bold;" *ngIf="vehiculeName.value==''">
                            Ajoutez le nom de la véhicule
                        </mat-error>
                    </div>

                    <!-- <div class="col-sm-4">
                        <legend for="storeName" class="space-bottom">Type des colis
                        </legend>
                        <select class="form-control" multiple [(ngModel)]="selectedTypeColis" name="profile">
                            <option [value]="type" *ngFor="let type of typeColis">
                                {{type}}</option>
                        </select>
                    </div> -->

                    <!-- <div class="col-sm-6">
                        <legend for="exampleSelect" class="space-bottom">
                            Localisation du dépôt </legend>
                        <select name="destination" id="destination" class="form-control" [(ngModel)]="selectedVille">
                            <option value="null">Selectionnez une ville</option>
                            <option *ngFor="let destination of villes" [ngValue]="destination" ngDefaultControl>
                                {{destination}}
                            </option>
                        </select>
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="selectedVille==null || selectedVille=='null'">
                            Ajoutez la localisation du dépôt
                        </mat-error>
                    </div> -->
                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="addVehicule(vehiculeName)" class="btn btn-primary">Ajouter
                            une véhicule</button>
                    </div>
                </div>
            </form>

            <!-- <form action="" method="post" enctype="multipart/form-data">
                <div class="form-group">
                    <label>Select video</label>
                    <input type="file" name="video" accept="video/*" class="form-control-file" (change)="handleVideoInput($event)">
                </div>

                <div class="form-group">
                    <label>Title</label>
                    <input type="text" name="title" class="form-control" #title
                    ngModel>
                </div>

                <input type="submit" class="btn btn-primary" value="Upload" (click)="upload(title)">
            </form> -->
        </div>
    </div>
</div>