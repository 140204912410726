<i class="material-icons" style="font-size: 100px;color: green">done</i>
<div mat-dialog-content>
    <h2 style="text-align:center">Veuillez sélectionner la véhicule</h2>
    <div class="div-form">
        <div class="form-group">
            <select class="form-control selectric" (change)="selectChangeHandler($event)">
                <option [value]="null">Selectionner une véhicule</option>
                <option [value]="veh._id" *ngFor="let veh of leadersList">
                    {{veh.vehicule_name}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <button [mat-dialog-close]="false" class="btn btn-info">NON</button>

    <button style="margin:0px 10px;" [mat-dialog-close]="true" class="btn btn-danger"
        *ngIf="data.vehicule!=null && data.vehicule!='null'">OUI</button>
</div>