import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccepteDemandeDialogComponent } from 'src/app/components/shared/dialogs/accepte-demande-dialog/accepte-demande-dialog.component';
import { AffecteTransDialogComponent } from 'src/app/components/shared/dialogs/affecte-trans-dialog/affecte-trans-dialog.component';
import { DemandesTransService } from 'src/app/services/demandes-trans/demandes-trans.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-demande',
  templateUrl: './details-demande.component.html',
  styleUrls: ['./details-demande.component.css']
})
export class DetailsDemandeComponent implements OnInit {

  
  user: any = null

  demande: any = {}
  demandeID: string = '';
  env: any;

  statusDemandes: any = [];

  mapLoaded: boolean;
  map: google.maps.Map;
  center: google.maps.LatLngLiteral;

  source: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 12
  }

  ds: google.maps.DirectionsService;
  dr: google.maps.DirectionsRenderer;

  constructor(private route: ActivatedRoute,
    // private teamService: TeamsService,
    private dialog: MatDialog,
    private transServices: DemandesTransService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    // this.getInfosUser()

    this.env = environment
    this.route.queryParams.subscribe(async (params) => {
      this.demandeID = params.demande;
      this._fetchDemande();
      // console.log(this.cmd);
    });

  }

  acceptedDemande() {
    if(this.demande != null && this.demande.price != null && this.demande.price != "") {
      const confirmDialog = this.dialog.open(AccepteDemandeDialogComponent, {
        data: {
          // destinationSac: destinationSac,
          user: null,
        }
      });
  
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          var data = confirmDialog.componentInstance.data
          // console.log("dataaaaaaaaaa : ")
          // console.log(JSON.stringify(data.user));
          // console.log("idSac : ")
          // console.log(idSac)
          var resDemande = {
            "idDemande": this.demande._id, 
            "idDriver": data.user._id,
            position: JSON.stringify({
              "lat": data.user.latitude,
              "lng": data.user.longitude
            }),
            "proposition": this.demande["price"],
            "acceptedBy": "transportor"
          }
          this.transServices.accepteDemande(resDemande).then((res: any) => {
            console.log("affectStorePrepa : ");
            console.log(res);
            if (res && !res.response) {
              alert(res.msg)
            } else {
              this.demande = res.data
            }
            // this.listStores();
            // else {
            //   // console.log("erreur");
            //   this.notif.notify('error', "Une erreur s'est produite");
            // }
          });
          
        }
      });
    } else {
      this.proposeDemande()
    }
    
  }

  async proposeDemande() {
    const confirmDialog = this.dialog.open(AffecteTransDialogComponent, {
      data: {
        // destinationSac: destinationSac,
        user: null,
        proposition: null
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(JSON.stringify(data.user));
        // console.log("idSac : ")
        // console.log(idSac)
        var position = {
          "lat": data.user.latitude,
          "lng": data.user.longitude
        }
        this.transServices.proposeDemande(this.demande._id, data.user._id, JSON.stringify(position), data.proposition).then((res: any) => {
          console.log("affectStorePrepa : ");
          console.log(res);
          if (res && !res.response) {
            alert(res.msg)
          } else {
            this.demande = res.data
          }
          // this.listStores();
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        
      }
    });
  }


  // async getInfosUser() {
  //   await this.teamService.getUserInfos().then((res: any) => {
  //     // console.log("getUserInfos");
  //     // console.log(res);
  //     if (res) {
  //       this.user = res;
  //     }
  //   });
  // }
  addAppel(idDemande) {
  //   if (this.demande.appel_by == null)
  //     this.transServices.addAppel(idDemande).then((data: any) => {
  //       // console.log("dddddddddddddd")
  //       // console.log(data)

  //       if (data) {
  //         this.demande = data;
  //       }
  //     });
  }

  addComment(comment) {
  //   if (comment.value != "")
  //     this.transServices.addComment(this.demande._id, this.user._id,
  //       this.user.prenom_user + " " + this.user.nom_user, "admin", comment.value.trim()).then((data: any) => {
  //         // console.log("dddddddddddddd")
  //         // console.log(data)

  //         if (data) {
  //           this.demande = data;
  //           comment.value = ""
  //         }
  //       });
  }

  _initMap() {
    this.ds = new google.maps.DirectionsService();
    this.dr = new google.maps.DirectionsRenderer({
      map: null,
      suppressMarkers: true
    });

    navigator.geolocation.getCurrentPosition(position => {

      this.source = {
        lat: this.demande.user_location.lat,
        lng: this.demande.user_location.lng
      };

      this.destination = {
        lat: this.demande.user_destination.lat,
        lng: this.demande.user_destination.lng
      };

      // initialize the map container
      this.map = new google.maps.Map(document.getElementById('map-canvas'), {
        ...this.options,
        center: this.source
      });

      this.map.addListener('tilesloaded', () => {
        this.mapLoaded = true;
      });

      // adding a marker
      var markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: './../../../../assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      var destinationMarker = new google.maps.Marker({
        position: this.destination,
        icon: {
          url: './../../../../assets/images/destination_map_marker2.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      this.setRoutePolyline();
    });
  }

  setRoutePolyline() {
    let request = {
      origin: this.source,
      destination: this.destination,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.ds.route(request, (response, status) => {
      this.dr.setOptions({
        suppressPolylines: false,
        map: this.map
      });

      if (status == google.maps.DirectionsStatus.OK) {
        this.dr.setDirections(response);
      }
    })
  }

  async _fetchDemande() {
    await this.transServices.getOneDemande(this.demandeID).then((d) => {
      console.log("ddddddddddddddddd")
      console.log(d)
      if (d) {
        this.demande = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/demandes-transportors';
      }
    });

    this._initMap();
  }

  goToNewTab(idDriver, route) {
    this.transServices.goToNewTab(idDriver, route)
  }

}
