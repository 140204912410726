<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Réceptionnistes
                <div class="page-title-subheading">La liste des Réceptionnistes des dépôts.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3" *ngFor="let prep of preparersList">
        <div class="card mb-3 bg-premium-dark widget-chart text-white card-border">
            <div class="photo-wrapper rounded-circle">
                <div class="icon-wrapper-bg bg-white opacity-1"></div><img class="rounded-circle"
                    width="300" src="{{env.transportorUsersAssetsBaseURL + prep?.profile_user}}" alt="product">
            </div>
            <div class="widget-heading">Réceptionniste: {{prep.nom_prenom_tracking}} </div>
            <div class="widget-description text-success">
                <!-- <button class="mb-2 mr-2 btn btn-danger">Détail colis<span
                        class="badge badge-pill badge-light">6</span></button> -->
            </div>
        </div>
    </div>
</div>