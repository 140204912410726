import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {

  imageUrl: string = '';
  fileToUpload: File = null;

  constructor(private transServices: GoupingService) { }

  ngOnInit(): void {
  }

  addSociety(firstName, lastName, username, password, confirmPassword, numTel) {
    if (firstName.value != "" && lastName.value != "" && username.value.includes('@')
      && username.value != "" /*&& this.imageUrl != ""*/ && password.value != '' && password.value == confirmPassword.value
      && numTel.value != "" && numTel.value.length == 8) {

      const formData: any = new FormData();

      if(this.fileToUpload != null) {
        formData.append("profile", this.fileToUpload, this.fileToUpload['name']);
      }
      
      var category = {
        nom: lastName.value,
        prenom: firstName.value,
        numtel: numTel.value,
        username: username.value,
        password: password.value
      }
      for (var key in category) {
        formData.append(key, category[key]);
      }

      // if (true) {
      if (confirm('Etes vous sûre?')) {
        this.transServices.addSociety(formData).then((res: any) => {
          // console.log("ressssss add cat : ")
          // console.log(res)
          if (res == true) {
            location.href = '/clients-phoenix';
          } else {
            alert(res.msg);
          }
        });
      }
    } else {
      alert('Les données de la société est invalide');
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

}
