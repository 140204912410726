import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-palets',
  templateUrl: './palets.component.html',
  styleUrls: ['./palets.component.css']
})
export class PaletsComponent implements OnInit {

  allPalets: any = []

  constructor(private cmdsService: GoupingService) { }

  ngOnInit(): void {
    this._fetchPalets()
  }

  async _fetchPalets() {
    // window.scrollTo(0, 380);

    await this.cmdsService.allPalets().then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.allPalets = res;
      }
    });
  }

}
