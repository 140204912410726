import { Component, OnInit } from '@angular/core';
import { GoupingService } from 'src/app/services/gouping-founix/gouping.service';

@Component({
  selector: 'app-cons',
  templateUrl: './cons.component.html',
  styleUrls: ['./cons.component.css']
})
export class ConsComponent implements OnInit {

  consList: any = []

  constructor(private sacsServices: GoupingService,) { }

  ngOnInit(): void {
    this.listCons()
  }

  async listCons() {
    await this.sacsServices.getCons().then((res: any) => {
      // console.log(res)
      if (res) {
        this.consList = res;
      }
    });
  }

  async generateCons() {
    await this.sacsServices.generateCons().then((res: any) => {
      // console.log(res)
      if (res) {
        this.listCons()
      }
    });
  }

  displayStatus(status) {
    if(status == "prepared") {
      return "valable"
    } else {
      return "expiré"
    }
  }

}
