import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StoresService } from 'src/app/services/stores/stores.service';
import { UsersService } from 'src/app/services/users-founix/users.service';

@Component({
  selector: 'app-add-transportor',
  templateUrl: './add-transportor.component.html',
  styleUrls: ['./add-transportor.component.css']
})
export class AddTransportorComponent implements OnInit {

  profiles = ["TRANSPORTEUR", "RECEPTION"];
  selectedProfiles: any = [];
  isResponsable: boolean = true;
  leadersList: Observable<Array<any>>;
  superieur: string = "";

  imageUrl: string = '';
  fileToUpload: File = null;

  storesList: Observable<Array<any>>;
  idStore: String = null;

  constructor(private trackingServices: UsersService,
    private storesService: StoresService) { }

  ngOnInit(): void {
    this.listStores()
  }

  selectStore(event: any) {
    this.idStore = event.target.value;
  }
  isProfileIncludes(profile) {
    if (this.selectedProfiles.includes(profile)) {
      return true;
    }
    return false
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  async listStores() {
    await this.storesService.getStores().then((res: any) => {
      if (res) {
        this.storesList = res;
      }
    });
  }

  async addUser(name, username, password, confirmPassword, numTel, numTel2) {
    if (name.value != "" && username.value.includes('@')
      && username.value != "" && this.imageUrl != "" && password.value != '' && password.value == confirmPassword.value
      && numTel.value != "" && numTel.value.length == 8 && this.selectedProfiles.length > 0
      && ((this.selectedProfiles.includes("RECEPTION") && this.idStore != null) || !this.selectedProfiles.includes("RECEPTION"))
    ) {

      const formData: any = new FormData();

      if (this.fileToUpload != null) {
        formData.append("profile", this.fileToUpload, this.fileToUpload['name']);
      }

      var user: any = {
        name: name.value,
        username: username.value,
        password: password.value,
        profil: this.selectedProfiles,
        numTel: numTel.value,
        numTel2: numTel2.value,
        pcollect: null,

        // responsable: isResponsable,
        // superieur: superieur
      }
      if (this.selectedProfiles.includes("RECEPTION")) {
        user.store = this.idStore
      }

      for (var key in user) {
        formData.append(key, user[key]);
      }

      await this.trackingServices.subscribe(formData)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            // if (this.selectedProfiles.includes("TRANSPORTEUR")) {
            //   location.href = '/tracking-user';
            // } else if (this.selectedProfiles.includes("PREPARATEUR")) {
            location.href = '/tracking-user';
            // }
            //this.sacsList = res;
          }
        });
    } else {
      alert("Les données de l'utilisateur est invalide");
    }
  }

}
